import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import PageWrapper from "@components/layouts/PageWrapper";
import Search from "@components/layouts/Search";
import { useLocation, useNavigate } from "react-router-dom";
import usePagination from "@hooks/usePagination";
import useSearch from "@hooks/useSearch";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { useDidMountEffect } from "@hooks/useDidMountEffect";
import { DeletePopup } from "@helpers/popups/DeletePopup";
import TableSkeleton from "@components/layouts/TableSkeleton";
import { TDeleteSuccesResponse } from "@type-defs/general/TDeleteSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import {
  useDeleteTripMutation,
  useGetAllTripApprovalsQuery,
} from "@states/trip/trip.api";
import { TRIP_MANAGEMENT } from "@utils/constants/trip/trip.constants";
import { tripColumns } from "@helpers/columns/trip/trip.columns";
import NoRecord from "@components/layouts/NoRecord";
import TripFilter from "@components/filters/TripFilter";
import InfoModal from "@components/modals/common/InfoModal";
import TripWarehouseDetail from "@components/extra/trip/TripWarehouseDetail";
import { tripApprovalColumns } from "@helpers/columns/trip/trip-approval.columns";
import AuthComponent from "@components/auth/component";

const TripApprovalPage = () => {
  const { page, limit, handlePageChange, handlePerRowsChange } =
    usePagination();
  const filterRef = useRef({
    from_date: undefined,
    to_date: undefined,
    region_id: undefined,
    trip_id: undefined,
  });
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const { search, setSearch } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data,
    isFetching,
    error: getAllError,
    refetch,
  } = useGetAllTripApprovalsQuery({
    page,
    limit,
    search: search,
    ...filterRef.current,
  });
  const [deleteTrip, { error: deleteError, isLoading }] =
    useDeleteTripMutation();

  // a custom hook that will handle fetch/mutation errors
  useErrorHandling(...castTypeArr<TFailResponse>([getAllError, deleteError]));

  useDidMountEffect(() => {
    refetch();
  }, [page, limit, search]);

  useEffect(() => {
    if (location.state?.reload) {
      refetch();
    }
  }, [location.state?.reload]);

  const editHandler = (id: string | number) => {
    navigate(`${TRIP_MANAGEMENT.APPROVAL_BASE_PATH}${id}/edit`);
  };

  const deleteHandler = async (id: number | string) => {
    try {
      const { isConfirmed } = await DeletePopup.fire();

      if (isConfirmed) {
        const res = await deleteTrip(id);

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TDeleteSuccesResponse;

        if (success) {
          Toast.fire({
            icon: "success",
            title: message,
          });

          refetch();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toggleModalHandler = async (id: any) => {
    // alert(id)
    if (id) {
      //@ts-ignore
      let warehouse = data?.data.filter((td) => {
        if (td.trip_id == id) {
          return td;
        }
      });
      setWarehouseData(warehouse[0].trip_warehouse_details);

      setShowInfoModal(true);
    }
  };

  return (
    <AuthComponent
      action="view"
      module="Trip"
      subModule="Trip Proposal Approval"
      page
    >
      <Search setSearch={setSearch} />
      <PageWrapper title={TRIP_MANAGEMENT.APPROVAL_LIST_PAGE_TITLE}>
        <InfoModal
          title="Allocation Account List"
          isOpen={showInfoModal}
          setIsOpen={setShowInfoModal}
        >
          <TripWarehouseDetail
            //@ts-ignore
            warehouseData={warehouseData}
          />
        </InfoModal>
        {/* @ts-ignore */}
        {/* {data?.data.length > 0 ? (
                    <TripFilter refetch={refetch} filterRef={filterRef} />
                ) : null} */}
        <TripFilter refetch={refetch} filterRef={filterRef} />
        <DataTable
          keyField="trip_id"
          responsive
          striped
          pagination
          paginationServer
          //@ts-ignore
          paginationTotalRows={data?.paginator?.totalItems}
          progressPending={isFetching}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={<NoRecord />}
          progressComponent={<TableSkeleton row={10} col={5} />}
          //@ts-ignore
          columns={tripApprovalColumns(editHandler, toggleModalHandler)}
          //@ts-ignore
          data={data?.data}
          // data={[{ region_name: 'Region A' }]}
        />
      </PageWrapper>
    </AuthComponent>
  );
};

export default TripApprovalPage;
