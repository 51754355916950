import React, { useState } from "react";
import PageWrapper from "@components/layouts/PageWrapper";
import BackButton from "@components/buttons/BackButton";
import SaleTargetAll from "../../../components/extra/sale-target/SaleTargetAll";
import AuthComponent from "@components/auth/component";

function AllTargetAddPage() {
  return (
    <AuthComponent
      action="create"
      module="Sales Target"
      subModule="All Target"
      page
    >
      <PageWrapper>
        <BackButton />
        <h3 className="mb-4 ml-2 text-2xl font-semibold text-primary-dark uppercase">
          Add New Sale Target
        </h3>
        <SaleTargetAll />
      </PageWrapper>
    </AuthComponent>
  );
}

export default AllTargetAddPage;
