import { useGetAllOfficeUsersQuery } from "@states/user-management/office-user.api";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { TUser } from "@type-defs/general/TUser";

const useGetAllOfficeUsers = (args: {
  page?: number;
  limit?: number;
  username?: string;
  status?: number;
  user_role_id?: number;
  type?: number;
}) => {
  const {
    data: rawData,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllOfficeUsersQuery({ ...args });

  const officeUserResponse = rawData as any as TSuccessResponse<TUser>;

  return {
    officeUserData: officeUserResponse?.data,
    officeUserMessage: officeUserResponse?.message,
    officeUserSuccess: officeUserResponse?.success,
    officeUserPaginator: officeUserResponse?.paginator,
    officeUserError: error,
    officeUserLoading: isLoading,
    officeUserRefetch: refetch,
    officeUserFetching: isFetching,
  };
};

export default useGetAllOfficeUsers;
