export const MARKETING_PROMOTION_PLAN_RETURN = {
  PRIMARY_KEY: "marketing_promotion_plan_return_id",
  BASE_PATH: "/marketing-promotion-return/returns/",
  ADD_PAGE_TITLE: "Add New Marketing Promotion Plan Return",
  LIST_PAGE_TITLE: "Marketing Promotion Plan Return List",
  EDIT_PAGE_TITLE: "Edit Marketing Promotion Plan Return",
  ADD_NEW_TITLE: "Add New Marketing Promotion Plan Return",
  GET_ALL: "/marketing-promotion-plan-return",
  GET_ALL_PAYMENTS: "/marketing-promotion-plan-return/make-payment",
  CREATE_PAYMENTS: "/marketing-promotion-plan-return/make-payment",
  DELETE_PAYMENTS: "/marketing-promotion-plan-return/make-payment",
  GET_ONE(id: string | number) {
    return `/marketing-promotion-plan-return/edit/${id}`;
  },
  ADD_PATH() {
    return this.BASE_PATH + "add";
  },
  CREATE: "/marketing-promotion-plan-return/create",
  UPDATE: "/marketing-promotion-plan-return/update/",
  DELETE: "/marketing-promotion-plan-return/delete/",
};
