export const BANK_INFORMATION = {
  BASE_PATH: "/bank-information/",
  GET_ALL: "/bank-information",
  CREATE: "/bank-information/create",
  UPDATE: "/bank-information/update/",
  DELETE: "/bank-information/delete/",
  GET_ONE: "/bank-information/edit/",
  ADD_NEW_TITLE: "Add New Bank Information",
  EDIT_NEW_TITLE: "Edit Bank Information",
};
