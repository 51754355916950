import AuthComponent from "@components/auth/component";
import DeleteButton from "../../../components/buttons/DeleteButton";
import EditButton from "../../../components/buttons/EditButton";
import { TSaleTarget } from "../../../types/sale-target/TSaleTarget";

export const AllTargetColumns = (
  editHandler: (saleTarget: TSaleTarget) => void,
  deleteHandler: (id: string | number) => void
) => [
  {
    name: "Sale Target ID",
    selector: (row: TSaleTarget) => row.sales_target_id,
  },
  {
    name: "Sale Person Name",
    selector: (row: TSaleTarget) => row.username,
  },
  {
    name: "Region Name",
    selector: (row: TSaleTarget) => row.region_name,
  },
  // {
  //   name: "Sale Target Type",
  //   selector: (row: TSaleTarget) =>
  //     row.sales_target_type_id == 1 ? "All Product" : "Product Target ",
  // },
  {
    name: "Total Sale Target Amount",
    selector: (row: TSaleTarget) => row.total_sales_target_amount,
  },
  {
    name: "Start Date",
    selector: (row: TSaleTarget) => row.format_start_date,
    right: true,
  },
  {
    name: "End Date",
    selector: (row: TSaleTarget) => row.format_end_date,
    right: true,
  },
  {
    name: "Action",
    right: true,
    selector: (row: TSaleTarget) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AuthComponent
          action="edit"
          module="Sales Target"
          subModule="All Target"
        >
          <EditButton
            id={row.sales_target_id}
            actionHandler={() => editHandler(row)}
          />
        </AuthComponent>

        <AuthComponent
          action="delete"
          module="Sales Target"
          subModule="All Target"
        >
          <DeleteButton
            id={row.sales_target_id}
            actionHandler={() => deleteHandler(row.sales_target_id)}
          />
        </AuthComponent>
      </div>
    ),
  },
];

export const AllTargetColumnNames = [
  "Sale Target ID",
  "Sale Person Name",
  "Region Name",
  // "Sale Target Type",
  "Total Sale Target Amount",
  "Start Date",
  "End Date",
  "Action",
];
