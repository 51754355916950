import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { CUSTOMER } from "../../utils/constants/customer.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const customerApi = createApi({
  reducerPath: "customerApi",
  tagTypes: ["Customer", "CustomerCreditLimitHistory"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllCustomers: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
        from_date?: string;
        to_date?: string;
        customer_type_id?: number;
        customer_category_id?: number;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        search,
        from_date,
        to_date,
        customer_type_id,
        customer_category_id,
      }) => {
        const queryParams = {
          url: CUSTOMER.GET_ALL,
          params: { page, limit },
        };
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (customer_type_id) {
          //@ts-ignore
          queryParams.params.customer_type_id = customer_type_id;
        }
        if (customer_category_id) {
          //@ts-ignore
          queryParams.params.customer_category_id = customer_category_id;
        }

        return queryParams;
      },
    }),
    getCustomer: builder.query<any, { id: string | number }>({
      query: ({ id }) => {
        return `${CUSTOMER.GET_ONE}${id}`;
      },
    }),
    // getAllContractCustomers: builder.query<any, void>({
    //     query: () => `consignment/contract/customer`,
    // }),
    getAllContractCustomers: builder.query<
      [],
      {
        page?: number;
        limit?: number;
      }
    >({
      query: ({ page = 1, limit = 10000 }) => {
        const queryParams = {
          url: "consignment/contract/customer",
          params: { page, limit },
        };

        return queryParams;
      },
    }),
    getCustomerAllCreditLimitHistory: builder.query<
      [],
      {
        id: string | number;
      }
    >({
      query: ({ id }) => `common/customer-credit-limit/${id}`,
      providesTags: ["CustomerCreditLimitHistory"],
    }),
    createCustomer: builder.mutation({
      query: (body) => ({
        url: CUSTOMER.CREATE,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomer: builder.mutation({
      query: ({ id, body }) => ({
        url: `${CUSTOMER.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CustomerCreditLimitHistory", id: arg.id },
      ],
    }),
    deleteCustomer: builder.mutation({
      query: (id) => ({
        url: `${CUSTOMER.DELETE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Customer"],
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllCustomersQuery,
  useCreateCustomerMutation,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useGetAllContractCustomersQuery,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useGetCustomerAllCreditLimitHistoryQuery,
} = customerApi;
