import { createApi } from "@reduxjs/toolkit/query/react";
import { MARKETING_PROMOTION_PLAN } from "@utils/constants/marketing-promotion-plan.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";
import { MARKETING_PROMOTION_PLAN_RETURN } from "@utils/constants/marketing-promotion-plan-return.constants";

export const MarketingPromotionPlanReturnApi = createApi({
  reducerPath: "marketingPromotionReturnApi",
  tagTypes: ["MarketingPromotionPlanReturn"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllPlanReturns: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        status?: number;
        name?: string;
        from_date?: string;
        to_date?: string;
      }
    >({
      query: ({ page = 1, limit = 10, status, name, from_date, to_date }) => {
        const queryParams = {
          url: MARKETING_PROMOTION_PLAN_RETURN.GET_ALL,
          params: { page, limit },
        };

        if (name) {
          //@ts-ignore
          queryParams.params.name = name;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    getPlanReturn: builder.query<any, { id: string | number }>({
      query: ({ id }) => {
        return MARKETING_PROMOTION_PLAN_RETURN.GET_ONE(id);
      },
    }),
    createPlanReturn: builder.mutation({
      query: (body) => ({
        url: MARKETING_PROMOTION_PLAN_RETURN.CREATE,
        method: "POST",
        body,
      }),
    }),
    updatePlanReturn: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PROMOTION_PLAN_RETURN.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deletePlanReturn: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PROMOTION_PLAN_RETURN.DELETE}${id}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllPlanReturnsQuery,
  useGetPlanReturnQuery,
  useCreatePlanReturnMutation,
  useUpdatePlanReturnMutation,
  useDeletePlanReturnMutation,
} = MarketingPromotionPlanReturnApi;
