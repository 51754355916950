import PageWrapper from "@components/layouts/PageWrapper";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import BackButton from "@components/buttons/BackButton";
import CustomSearchModalChooseField from "@components/form/CustomSearchModalChooseField";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@states/store";

import CustomButton from "@components/buttons/CustomButton";
import CustomDateField from "@components/form/CustomDateField";
import CustomSelectField from "@components/form/CustomSelectField";
import CustomInputField from "@components/form/CustomInputField";

import { GOODS_ISSUE_NOTE } from "@utils/constants/goods-request/goods-issue-note.contants";

import {
  goodsDeliverySchema,
  ZGoodsDelivery,
} from "@helpers/validation-schemas/goods-request/goods-delivery-note.schema";

import { useUpdateGoodsDeliveryMutation } from "@states/goods-request/delivery-note.api";
import { useLazyGetInvoiceQuery } from "@states/goods-request/goods-request.api";
import DeleteButton from "@components/buttons/DeleteButton";
import CustomFileField from "@components/form/CustomFileField";

import useGetOneGoodsDelivery from "@hooks/goods-request/useGetOneGoodsDelivery";
import { GOODS_DELIVERY_NOTE } from "@utils/constants/goods-request/delivery-note.constants";
import { formatUTCDate } from "@utils/general/format-utc-date";
import { formatDBDate } from "@utils/general/format-db-date";
import AuthComponent from "@components/auth/component";

const GoodsDeliveryNoteEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { formValues } = useSelector((state: RootState) => state.common);
  const { goodsDeliveryData, goodsDeliveryRefetch } = useGetOneGoodsDelivery(
    id!
  );

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<ZGoodsDelivery>({
    resolver: zodResolver(goodsDeliverySchema),
  });

  console.log("Errors", errors);

  const { append, remove, update, replace } = useFieldArray({
    control,
    name: "product_detail",
  });

  const [updateGoodDelivery, { isLoading, isSuccess, error: createError }] =
    useUpdateGoodsDeliveryMutation();

  useErrorHandling(...castTypeArr<TFailResponse>([createError]));

  // useEffect(() => {
  //   const invoiceData = invoiceResponse as any;
  //   if (invoiceData && invoiceData.data) {
  //     const data = invoiceData?.data;
  //     const invoice = data?.invoice;
  //     const invoice_detail = data?.invoice_detail;
  //     const products_detail = invoice_detail?.map((product: any) => {
  //       const productDetail = {
  //         qty: product.qty,
  //         product_code: product.product_code,
  //         product_name: product.product_name,
  //         product_id: product.product_id,
  //         unit_id: product.unit_id,
  //         unit_name: product.unit_name,
  //         balance: product.balance,
  //         issue_qty: 0,
  //         request_qty: product.request_qty,
  //       };
  //       append(productDetail);
  //       return productDetail;
  //     });

  //     const formValues = {
  //       product_detail: products_detail,
  //       invoice_date: invoice?.invoice_date,
  //       warehouse_name: invoice?.warehouse_name,
  //       customer_name: invoice?.customer_first_name,
  //       customer_id: invoice?.customer_id,
  //       warehouse_id: invoice?.warehouse_id,
  //       sales_date: invoice?.sales_date ?? new Date(),
  //       goods_request_note_id: invoice?.goods_request_note_id,
  //       //@ts-ignore
  //       // invoice_id: invoiceReference?.current?.value,
  //     };
  //     Object.entries(formValues).forEach(([key, value]) => {
  //       //@ts-ignore
  //       setValue(key, value);
  //     });
  //   } else {
  //     const formValues = {
  //       product_detail: [],
  //       invoice_date: null,
  //       warehouse_name: "",
  //       customer_name: "",
  //       customer_id: null,
  //       warehouse_id: null,
  //       sales_date: null,
  //       //@ts-ignore
  //       // invoice_id: invoiceReference?.current?.value,
  //     };
  //     Object.entries(formValues).forEach(([key, value]) => {
  //       //@ts-ignore
  //       setValue(key, value);
  //     });
  //   }
  // }, [invoiceResponse]);

  useEffect(() => {
    if (goodsDeliveryData) {
      const products_detail = goodsDeliveryData?.product_detail?.map(
        (product: any) => {
          const productDetail = {
            issue_qty: product.issue_qty,
            unit_name: product.unit_name,
            request_qty: product.request_qty,
            product_name: product.product_name,
            total_issued_qty: product.total_issued_qty,
            issued_balance_qty: product.issued_balance_qty,
          };
          return productDetail;
        }
      );

      const formValues = {
        delivery_note_date: goodsDeliveryData?.delivery_note_date,
        delivery_date: goodsDeliveryData?.delivery_date,
        delivery_complete_date: formatDBDate(
          goodsDeliveryData?.delivery_note_date
        ),
        status: goodsDeliveryData.status,
        product_detail: products_detail,
        invoice_date: goodsDeliveryData?.invoice_date,
        business_unit_name: goodsDeliveryData?.business_unit_name,
        customer_name: goodsDeliveryData?.customer_first_name,
        business_unit_phone: goodsDeliveryData.customer_phone || "",
        business_unit_address: goodsDeliveryData.business_unit_address || "",
        customer_phone: goodsDeliveryData?.customer_phone || "",
        customer_address: goodsDeliveryData?.customer_address || "",
        description: goodsDeliveryData?.description || "",
      };
      Object.entries(formValues).forEach(([key, value]) => {
        //@ts-ignore
        setValue(key, value);
      });
    }
  }, [goodsDeliveryData]);

  console.log("errors", errors);

  const onSubmit: SubmitHandler<ZGoodsDelivery> = async (data) => {
    try {
      // async stuff here
      // console.log(data, 'data');
      await asyncDispatcher(data);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const { status, delivery_complete_date, file, description } = reqBody;
        console.log(formValues, "reqBody");
        const formData = new FormData();

        if (status) {
          formData.append("status", status);
        }

        if (delivery_complete_date) {
          formData.append("delivery_complete_date", delivery_complete_date);
        }

        if (description) {
          formData.append("description", description);
        }

        if (file[0]) {
          formData.append("file", file[0]);
        }

        console.log("formData===>", formData);
        const res = await updateGoodDelivery({
          id: id!,
          body: formData,
        });
        const successData = "data" in res ? res.data : null;
        console.log("Reposne", successData);

        const { success, message } = successData as TSuccessResponse;
        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });
          navigate(GOODS_DELIVERY_NOTE.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [updateGoodDelivery, isSuccess, Toast, navigate, formValues]
  );

  return (
    <AuthComponent
      action="edit"
      module="Goods Request"
      subModule="Delivery Note"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <BackButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center justify-between mb-4">
            <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
              EDIT GOOD DELIVERY
            </h3>
          </div>
          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <CustomDateField
              errors={errors}
              name="delivery_complete_date"
              label="Deliver Complete Date"
              register={register}
              key="delivery_complete_date"
              //@ts-ignore
              control={control}
              required
              //@ts-ignore
              // minDate={
              //   goodsDeliveryData?.delivery_note_date &&
              //   new Date(goodsDeliveryData?.delivery_note_date)
              // }
              minDate={
                goodsDeliveryData?.delivery_note_date &&
                goodsDeliveryData?.delivery_note_date
              }
            />

            <CustomSelectField
              //@ts-ignore
              control={control}
              errors={errors}
              name="status"
              label="Status"
              options={[
                {
                  label: "Pending",
                  value: 1,
                },
                {
                  label: "Delivered",
                  value: 2,
                },
              ]}
              value={formValues.status || 3}
              required
            />
            <div
              className="flex flex-col p-2"
              style={{ gridColumn: "auto / span 2" }}
            >
              <label
                className="mb-2 text-gray-700"
                htmlFor="reference"
                style={{ textTransform: "capitalize" }}
              >
                Reference ID
              </label>
              <input
                disabled
                name="reference"
                type="text"
                className="px-2 py-3 border-[0.7px] focus:ring-1 ring-primary focus:outline-none rounded border-primary-light"
                placeholder="Enter reference id"
                defaultValue={goodsDeliveryData?.reference_id || ""}
              />
            </div>

            <CustomDateField
              errors={errors}
              name="invoice_date"
              label="Invoice Date"
              register={register}
              key="invoice_date"
              //@ts-ignore
              control={control}
              disabled
              defaultValue={goodsDeliveryData?.invoice_date || ""}
            />
            <CustomDateField
              errors={errors}
              name="delivery_date"
              label="Delivery Date"
              register={register}
              key="delivery_date"
              //@ts-ignore
              control={control}
              disabled
              defaultValue={goodsDeliveryData?.delivery_date || ""}
            />
            <CustomDateField
              errors={errors}
              name="delivery_note_date"
              label="Issue Date"
              register={register}
              key="delivery_note_date"
              //@ts-ignore
              control={control}
              disabled
            />

            <CustomInputField
              errors={errors}
              key="customer_name"
              register={register}
              name="customer_name"
              inputType="text"
              disabled
              label="Customer Name"
              defaultValue={goodsDeliveryData?.customer_first_name || ""}
            />

            <CustomInputField
              errors={errors}
              key="customer_phone"
              register={register}
              name="customer_phone"
              inputType="text"
              disabled
              label="Customer Phone Number"
              defaultValue={goodsDeliveryData?.customer_phone || ""}
            />

            <CustomInputField
              errors={errors}
              key="customer_address"
              register={register}
              name="customer_address"
              inputType="text"
              disabled
              label="Customer Address"
              defaultValue={goodsDeliveryData?.customer_address || ""}
            />
            <CustomInputField
              errors={errors}
              key="business_unit_name"
              register={register}
              name="business_unit_name"
              inputType="text"
              disabled
              label="Business Unit Name"
              defaultValue={goodsDeliveryData?.business_unit_name || ""}
            />
            <CustomInputField
              errors={errors}
              key="business_unit_name"
              register={register}
              name="business_unit_name"
              inputType="text"
              disabled
              label="Business Unit Phone Number"
              defaultValue={goodsDeliveryData?.business_unit_phone || ""}
            />

            <CustomInputField
              errors={errors}
              key="business_unit_address"
              register={register}
              name="business_unit_address"
              inputType="text"
              disabled
              label="Business Unit Address"
              defaultValue={goodsDeliveryData?.business_unit_address || ""}
            />

            <CustomFileField
              isFile
              label="Attach File"
              placeHolder="Attach a file here"
              errors={errors}
              name="file"
              register={register}
              setValue={setValue}
              watch={watch}
              isViewPdf
            />
            <CustomTextAreaField
              value={goodsDeliveryData?.description || "-"}
              key={"description"}
              errors={errors}
              colSpan={6}
              name="description"
              // disabled
              register={register}
            />
            <div className="flex justify-between col-span-6"></div>
            <div className="flex flex-col justify-between col-span-6">
              <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                <span className=" text-[1.3rem]">Product Detail</span>
              </h3>
            </div>
            <div className="container w-full -mt-4 col-span-full">
              <div className="w-[100%] mx-auto cursor-grab active:cursor-grabbing overflow-x-auto hide-scrollbar">
                <table className="min-w-full overflow-x-scroll custom-table">
                  <thead className="h-12 py-3 text-xs font-light text-netural-600 bg-neutral-100">
                    <th className="text-xs font-light 2xl:text-sm">
                      Product Name
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Allocation Account Name
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">Unit</th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Request QTY
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Total Issued Qty
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Issued Balance
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Issue QTY
                    </th>
                    {/* 
                  <th className="sticky right-0 text-xs font-light 2xl:text-sm bg-inherit">
                    Action
                  </th> */}
                  </thead>
                  <tbody className="text-center">
                    {goodsDeliveryData?.product_detail?.map(
                      (product_detail: any, i: number) => {
                        console.log("PRDOOOD", product_detail);
                        return (
                          <tr key={i}>
                            <td>
                              <CustomInputField
                                readOnly
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                disabled
                                name={`product_detail.${i}.product_name`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.product_name}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.warehouse_name`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.warehouse_name}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.unit_name`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.unit_name}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.request_qty`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.request_qty}
                              />
                            </td>

                            <td>
                              <div
                                className="flex flex-col p-2 w-[100%]"
                                style={{ gridColumn: "auto / span 2" }}
                              >
                                <input
                                  name={`product_detail.${i}.balance`}
                                  disabled
                                  readOnly
                                  type="text"
                                  className="px-2 py-3 border-[0.7px] focus:ring-1 bg-zinc-100 pointer-events-none cursor-not-allowed ring-primary focus:outline-none rounded border-primary-light"
                                  placeholder=""
                                  value={product_detail?.total_issued_qty}
                                />
                              </div>{" "}
                            </td>
                            <td>
                              <div
                                className="flex flex-col p-2 w-[100%]"
                                style={{ gridColumn: "auto / span 2" }}
                              >
                                <input
                                  name={`product_detail.${i}.balance`}
                                  disabled
                                  readOnly
                                  type="text"
                                  className="px-2 py-3 border-[0.7px] focus:ring-1 bg-zinc-100 pointer-events-none cursor-not-allowed ring-primary focus:outline-none rounded border-primary-light"
                                  placeholder=""
                                  value={product_detail?.issued_balance_qty}
                                />
                              </div>{" "}
                            </td>
                            <td>
                              <div
                                className="flex flex-col p-2 w-[100%]"
                                style={{ gridColumn: "auto / span 2" }}
                              >
                                <input
                                  disabled
                                  readOnly
                                  type="text"
                                  className="px-2 py-3 border-[0.7px] focus:ring-1 bg-zinc-100 pointer-events-none cursor-not-allowed ring-primary focus:outline-none rounded border-primary-light"
                                  placeholder=""
                                  value={product_detail?.issue_qty}
                                />
                              </div>{" "}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="ml-2 mt-14">
            <CustomButton
              isLarge
              title="Cancel"
              color="white"
              textColor="gray.800"
              className="mr-10"
              handleClick={() => navigate(-1)}
            />
            <CustomButton
              handleClick={handleSubmit(onSubmit)}
              isLarge
              title={isSubmitting ? "Loading..." : "Submit"}
              disabled={isSubmitting}
              type="submit"
            />
          </div>
          {errors.root && (
            <div className="mt-6 text-red-500">{errors.root.message}</div>
          )}
        </form>
      </PageWrapper>
    </AuthComponent>
  );
};

export default GoodsDeliveryNoteEditPage;
