import { useState } from "react";
import BackButton from "@components/buttons/BackButton";
import CustomDateField from "@components/form/CustomDateField";
import CustomInputField from "@components/form/CustomInputField";
import CustomSearchModalChooseField from "@components/form/CustomSearchModalChooseField";
import CustomSelectField from "@components/form/CustomSelectField";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import PageWrapper from "@components/layouts/PageWrapper";
import useGetAllWarehouses from "@hooks/warehouse-and-inventory/useGetAllWarehouses";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@states/store";
import { thousandSeperator } from "@utils/general/digit-separators";
import { productDetailColumns } from "@helpers/columns/primary-sale-order/product-detail.columns";
import { useCallback, useEffect } from "react";
import { addFormValues, resetFormValues } from "@states/common/common.slice";

import {
  ZInvoiceConsignment,
  invoiceConsignmentSchema,
} from "@helpers/validation-schemas/consignment/invoice.schema";
import CustomButton from "@components/buttons/CustomButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateConsignmentInvoiceMutation } from "@states/consignment/consignment-invoice.api";
import { Toast } from "@helpers/popups/Toast";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { removeKeys } from "@utils/general/removeKeys";
import { calculatePercentage } from "@utils/general/cal-percent";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { CONSIGNMENT_INVOICE } from "@utils/constants/consignment/consignment-invoice.constants";
import {
  useGetConsignmentContractCustomerQuery,
  useGetConsignmentContractProductDetailQuery,
  useLazyGetConsignmentContractProductDetailQuery,
  useCreateConsignmentMutation,
  useLazyGetConsignmentContractListByBuQuery,
} from "@states/consignment/consignment.api";
import { useGetAllPaymentTermsQuery } from "@states/common/common.api";
import useGetAllRegions from "@hooks/distribution-region/useGetAllRegions";
import { consignmentproductDetailColumns } from "@helpers/columns/consignment/consignment-detail.columns";
import AuthComponent from "@components/auth/component";

const ConsignmentInvoiceAddPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const consignment_id = useParams();
  const { formValues } = useSelector((state: RootState) => state.common);
  const [businessUnits, setBusinessUnits] = useState<any>([]);

  const {
    customer_id,
    customer_name,
    payment_type_id,
    payment_terms_id,
    customer_first_name,
    customer_last_name,
    business_unit_name,
    description,
    business_unit_id,
    product_detail,
    payment_term_id,
    sub_total,
    discount_amount,
    discount_type,
    tax_amount,
    tax_type,
    other_charges,
    tax,
    discount,
    grand_total_amount,
    sales_date,
    region_id,
    consignment_contract_id,
  } = location.state?.carriedFormValues;
  const defaultValues = {
    invoice_date: new Date(sales_date),
    due_date: new Date(
      new Date(sales_date).setDate(new Date(sales_date).getDate() + 3)
    ),
    customer_id,
    customer_name,
    payment_type_id,
    payment_terms_id,
    customer_first_name,
    customer_last_name,
    region_id,
    business_unit_name,
    consignment_contract_id,
    description,
    discount,
    tax,
    business_unit_id,
    product_detail,
    payment_term_id,
    sub_total,
    discount_amount,
    discount_type,
    tax_amount,
    tax_type,
    other_charges,
    grand_total_amount,
    sales_date,
  };

  console.log("Business Unit", defaultValues);

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<ZInvoiceConsignment>({
    defaultValues,
    resolver: zodResolver(invoiceConsignmentSchema),
  });
  const {
    regionData,
    regionError,
    regionRefetch,
    regionFetching,
    regionPaginator,
  } = useGetAllRegions({
    limit: 50,
  });
  const { warehouseData } = useGetAllWarehouses({});
  const { data: customerData } = useGetConsignmentContractCustomerQuery({
    page: 1,
    limit: 10,
  });
  const [createConsignmentInvoice, { isSuccess, isLoading }] =
    useCreateConsignmentInvoiceMutation();
  const [
    getConsignmentContractListByBu,
    { data: contractListByBuData, isLoading: contractListByBuDataLoading },
  ] = useLazyGetConsignmentContractListByBuQuery();
  useEffect(() => {
    reset(defaultValues);

    dispatch(addFormValues(defaultValues));
  }, [location, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      dispatch(addFormValues(value));
    });

    return () => {
      subscription.unsubscribe();
      dispatch(resetFormValues());
    };
  }, [watch, dispatch]);
  useEffect(() => {
    if (formValues?.business_unit_id) {
      getConsignmentContractListByBu({
        business_unit_id: formValues?.business_unit_id,
      });
    }
  }, [formValues?.business_unit_id]);
  useEffect(() => {
    if (formValues?.customer_id && customerData?.data) {
      const customer = customerData?.data.find(
        (c: any) => c.customer_id == formValues?.customer_id
      );

      if (customer?.business_units && customer.business_units.length > 0) {
        setBusinessUnits(customer?.business_units || []);
      }
    }
  }, [formValues?.customer_id, customerData]);

  const handleRemove = (index: number) => {};

  const onSubmit: SubmitHandler<ZInvoiceConsignment> = async (data) => {
    try {
      // async stuff here
      console.log(data, "data");
      await asyncDispatcher(data);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };
  const { data: paymentTermData } = useGetAllPaymentTermsQuery({ status: 0 });

  useEffect(() => {
    if (formValues?.invoice_date && payment_type_id && payment_type_id != 3) {
      const newDate = new Date(formValues?.due_date);

      newDate && setValue("due_date", newDate);
    }
  }, [formValues?.invoice_date]);

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const { product_detail, customer_name, ...rest } = reqBody;
        delete rest.consignment_contract_id;
        const formattedProductDetails = product_detail?.map((pd: any) => ({
          product_id: pd.product_id,
          unit_id: pd.unit_id,
          sales_price: pd.sales_price,
          qty: pd.qty,
          amount: pd.amount,
          normal_discount_type: pd.normal_discount_type,
          normal_discount: pd.normal_discount,
          discount_type: pd.discount_type,
          discount_amount: pd.discount_amount,
          tax_type: pd.tax_type,
          tax_amount: pd.tax_amount,
          total_amount: pd.total_amount,
          warehouse_id: pd.warehouse_id,
          discount: pd.discount,
          tax: pd.tax,
          consignment_contract_qty: pd.contract_qty,
        }));
        delete rest.sales_date;
        delete rest.payment_term_id;
        const res = await createConsignmentInvoice({
          ...rest,
          // payment_terms_id : rest.payment_term_id,
          consignment_id: consignment_id.id,
          region_id: region_id,
          // balance: rest.grand_total_amount,
          products: formattedProductDetails,
        });
        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TSuccessResponse;
        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });
          navigate(CONSIGNMENT_INVOICE.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [createConsignmentInvoice, isSuccess, Toast, navigate, formValues]
  );

  return (
    <AuthComponent
      action="create"
      module="Consignment"
      subModule="Invoice"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <BackButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
            Convert To Invoice
          </h3>
          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <CustomDateField
              errors={errors}
              name="invoice_date"
              label="Invoice Date"
              register={register}
              //@ts-ignore
              control={control}
              required
              minDate={formValues?.sales_date}
            />
            <CustomDateField
              errors={errors}
              name="due_date"
              label="Due Date"
              register={register}
              //@ts-ignore
              control={control}
              required
              maxDate={formValues?.due_date}
              minDate={formValues?.sales_date}
            />
            <CustomSearchModalChooseField
              key={"customer_id"}
              errors={errors}
              colSpan={2}
              name={"customer_id"}
              label="Customer Name"
              title={"Customer List"}
              columns={[
                {
                  name: "customer_name",
                  columnName: "Customer Name",
                },
                {
                  name: "phone_number",
                  columnName: "Phone Number",
                },
              ]}
              register={register}
              // borderColor={field.borderColor}
              placeHolder="Choose customer name"
              setValue={setValue}
              columnName={"Customer Name"}
              idName={"customer_id"}
              // value={field.value}
              nameName={"customer_name"}
              disabled
              value={customer_name}
            />
            <CustomSelectField
              errors={errors}
              name="consignment_contract_id"
              label="Consignment Contract"
              placeHolder="Select Consignment Contract"
              //@ts-ignore
              control={control}
              //@ts-ignore
              options={contractListByBuData?.data?.map((item: any) => ({
                label: item.consignment_contract_code,
                value: item.consignment_contract_id,
              }))}
              required
              disabled
            />
            <CustomSelectField
              errors={errors}
              name="region_id"
              label="Distribution Region"
              placeHolder="Select Distribution Region"
              //@ts-ignore
              control={control}
              //@ts-ignore
              options={regionData?.map((item: any) => ({
                label: item.region_name,
                value: item.region_id,
              }))}
              required
              disabled
            />
            <CustomSelectField
              errors={errors}
              name="payment_type_id"
              label="Payment Type"
              disabled
              //@ts-ignore
              control={control}
              options={[
                {
                  label: "Credit",
                  value: 1,
                },
                { label: "Consignment", value: 2 },
                { label: "Cashdown", value: 3 },
              ]}
              value={payment_type_id}
              required
            />
            <CustomSelectField
              errors={errors}
              name="payment_term_id"
              label="Payment Term"
              placeHolder="Select payment term"
              //@ts-ignore
              control={control}
              options={paymentTermData?.map((item: any) => ({
                label: item.payment_terms,
                value: item.payment_terms_id,
              }))}
              value={payment_term_id}
              disabled
            />
            <CustomSelectField
              disabled
              errors={errors}
              name="business_unit_id"
              label="Business Unit"
              placeHolder="Select business unit"
              //@ts-ignore
              control={control}
              options={businessUnits?.map((bu: any) => ({
                label: bu.business_unit_name,
                value: bu.business_unit_id,
              }))}
            />{" "}
            <div></div>
            <div></div>
            {/* <CustomTextAreaField
                        key={'remark'}
                        colSpan={3}
                        errors={errors}
                        name="remark"
                        register={register}
                    /> */}
            <CustomTextAreaField
              key={"description"}
              errors={errors}
              colSpan={6}
              name="description"
              register={register}
              //@ts-ignore
              value={description}
            />
            <div className="flex justify-between col-span-6">
              <h3 className="ml-2 font-[600] text-primary-dark">
                <span className=" text-[1.1rem]">Add Consignment Detail</span>
              </h3>
            </div>
            <div className="flex flex-col justify-between col-span-6">
              <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                <span className=" text-[1.3rem]">Product Detail</span>
              </h3>
            </div>
            <div className="-mt-4 col-span-full">
              <DataTable
                className="pb-4"
                responsive
                striped
                //@ts-ignore
                columns={consignmentproductDetailColumns(
                  errors,
                  register,
                  setValue,
                  handleRemove,
                  control,
                  true
                )}
                //@ts-ignore
                data={formValues?.product_detail?.map((f, i) => ({
                  ...f,
                  index: i,
                }))}
              />

              {formValues?.product_detail?.length > 0 && (
                <div className="bg-zinc-100 opacity-[0.6] rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                  <div className="flex items-center mb-4">
                    <h5 className="text-[1.05rem] basis-3/12">Sub Total</h5>
                    <span className="s basis-1/12">:</span>
                    <div className="flex items-center basis-6/12"></div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.sub_total || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className=" basis-3/12 text-[1.05rem]">Discount</h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        disabled
                        defaultValue={
                          formValues.discount_type !== "AMT"
                            ? calculatePercentage(
                                formValues?.sub_total || 0,
                                formValues?.discount_amount || 0
                              )
                            : formValues?.discount_amount
                        }
                        classNames="-mr-3 w-[130px]"
                        noLabel
                        inputType="number"
                        errors={errors}
                        name={"discount"}
                        placeHolder=" "
                        register={register}
                      />
                      <select
                        disabled
                        {...register("discount_type")}
                        className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                      >
                        <option value="AMT">AMT</option>
                        <option value="%">%</option>
                      </select>
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      -{thousandSeperator(formValues.discount_amount || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className=" basis-3/12 text-[1.05rem]">Tax</h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        disabled
                        defaultValue={
                          formValues.tax_type !== "AMT"
                            ? calculatePercentage(
                                formValues?.sub_total || 0,
                                formValues?.tax_amount || 0
                              )
                            : formValues?.tax_amount
                        }
                        classNames="-mr-3 w-[130px]"
                        noLabel
                        inputType="number"
                        errors={errors}
                        name={"tax"}
                        placeHolder=" "
                        register={register}
                      />
                      <select
                        disabled
                        {...register("tax_type")}
                        className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                      >
                        <option value="AMT">AMT</option>
                        <option value="%">%</option>
                      </select>
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.tax_amount || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-4">
                    <h5 className=" basis-3/12 text-[1.05rem]">
                      Other Charges
                    </h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        disabled
                        defaultValue={0}
                        classNames="-mr-3 w-[200px]"
                        noLabel
                        inputType="number"
                        errors={errors}
                        name={"other_charges"}
                        placeHolder=" "
                        register={register}
                      />
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.other_charges || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className="text-xl font-semibold basis-3/12">
                      Grand Total
                    </h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12"></div>
                    <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                      {thousandSeperator(formValues.grand_total_amount || 0)}
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ml-2 mt-14">
            <CustomButton
              isLarge
              title="Cancel"
              color="white"
              textColor="gray.800"
              className="mr-10"
              handleClick={() => navigate(-1)}
            />
            <CustomButton
              handleClick={handleSubmit(onSubmit)}
              isLarge
              title={isSubmitting ? "Loading..." : "Submit"}
              disabled={isSubmitting}
              type="submit"
            />
          </div>
          {errors.root && (
            <div className="mt-6 text-red-500">{errors.root.message}</div>
          )}
        </form>
      </PageWrapper>
    </AuthComponent>
  );
};

export default ConsignmentInvoiceAddPage;
