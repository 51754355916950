import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import CustomButton from "../../buttons/CustomButton";
import expandStyles from "../styles/expandStyles";
import ItemListTable from "@components/extra/sales-order/ItemListTable";
import TripProgressTable from "@components/extra/trip/TripProgress";
import { formatDBDate } from "@utils/general/format-db-date";
import { formatDate } from "@utils/general/format-date";
import { thousandSeperator } from "@utils/general/digit-separators";
import CustomInputField from "@components/form/CustomInputField";
import PromotionItemListTable from "@components/extra/sales-order/PromotionItemListTable";

interface SaleOrderPromotionDetailModalProps {
  data: any;
  isOpen: boolean;
  amount: any;
  qty: any;
  base_qty: any;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaleOrderPromotionDetailModal: React.FC<
  SaleOrderPromotionDetailModalProps
> = ({ data, isOpen, setIsOpen, amount, qty, base_qty }) => {
  console.log("SaleOrderPromotionDetailModal", data);
  return (
    <Modal isOpen={isOpen} style={expandStyles} contentLabel="Example Modal">
      <div className="relative h-full">
        <div className="flex justify-end">
          <svg
            onClick={() => setIsOpen(false)}
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#3e3e3e"
            className="cursor-pointer"
          >
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
          </svg>
        </div>
        <div className="py-4 px-14">
          <div className="grid grid-cols-1 gap-x-8 gap-y-7">
            <div className="flex justify-between">
              <div className="font-[500] basis-5/12">Promotion Name</div>
              <div className="basis-1/12">:</div>
              <div className="basis-6/12 font-[300] text-[0.85rem]">
                {data?.promotion_name}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-[500] basis-5/12">Unit Name</div>
              <div className="basis-1/12">:</div>
              <div className="basis-6/12 font-[300] text-[0.85rem]">
                {data?.sku && data?.sku.map((sk: any) => sk.label).join(", ")}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-[500] basis-5/12">Range Type</div>
              <div className="basis-1/12">:</div>
              <div className="basis-6/12 font-[300] text-[0.85rem]">
                {data?.range_type}
              </div>
            </div>

            <PromotionItemListTable
              data={data?.info}
              amount={amount}
              qty={qty * base_qty}
              range_type={data?.range_type}
            />
            {/* <h1>{base_qty}</h1> */}
            <div className="float-right pb-8 mt-10">
              <CustomButton
                handleClick={() => setIsOpen(false)}
                color="bg-primary-dark"
                className="px-8 py-3"
                title="Close"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SaleOrderPromotionDetailModal;
