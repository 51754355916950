import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "@states/auth/auth.api";
import { MARKETING_PROMOTION_PLAN_RETURN } from "@utils/constants/marketing-promotion-plan-return.constants";

export const MarketingMakePaymentApi = createApi({
  reducerPath: "marketingPaymentReturnApi",
  tagTypes: ["MarketingPaymentReturn"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllPayments: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        status?: number;
        from_date?: string;
        to_date?: string;
        code?: string;
      }
    >({
      query: ({ page = 1, limit = 10, status, from_date, to_date, code }) => {
        const queryParams = {
          url: MARKETING_PROMOTION_PLAN_RETURN.GET_ALL_PAYMENTS,
          params: { page, limit },
        };

        if (code) {
          //@ts-ignore
          queryParams.params.code = code;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }
        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        return queryParams;
      },
    }),
    createMakePayment: builder.mutation({
      query: (body) => ({
        url: MARKETING_PROMOTION_PLAN_RETURN.CREATE_PAYMENTS,
        method: "POST",
        body,
      }),
    }),
    deleteMakePayment: builder.mutation({
      query: ({ id, body }) => ({
        url: `${MARKETING_PROMOTION_PLAN_RETURN.DELETE_PAYMENTS}/${id}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllPaymentsQuery,
  useCreateMakePaymentMutation,
  useDeleteMakePaymentMutation,
} = MarketingMakePaymentApi;
