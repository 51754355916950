import { useState, useEffect } from "react";
import { useGetAllProductsQuery } from "@states/product/product.api";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { TProduct } from "@type-defs/product/TProduct";

const useGetAllProducts = (args: {
  page?: number;
  limit?: number;
  product_category_id?: string;
  product_name?: string;
}) => {
  const [page, setPage] = useState(args.page || 1);
  const [products, setProducts] = useState<TProduct[]>([]);

  const {
    data: rawData,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllProductsQuery({
    ...args,
    page, // Use state for pagination
  });
  //@ts-ignore
  const productResponse = rawData as TSuccessResponse<TProduct>;

  useEffect(() => {
    if (productResponse?.data) {
      setProducts((prev) => [...prev, ...productResponse.data]); // Append new data
    }
  }, [productResponse]);

  return {
    productData: products,
    productMessage: productResponse?.message,
    productSuccess: productResponse?.success,
    productPaginator: productResponse?.paginator,
    productError: error,
    productLoading: isLoading,
    productRefetch: refetch,
    productFetching: isFetching,
    onMenuScrollToBottom: () => setPage((prev) => prev + 1), // Function to load more
  };
};

export default useGetAllProducts;
