import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";

export const commonApi = createApi({
  reducerPath: "commonApi",
  tagTypes: [
    "UserRole",
    "CustomerHistory",
    "PriceHistory",
    "StandardAppSchema",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllUserRoles: builder.query<[], void>({
      query: () => "user-roles",
    }),
    createUserRole: builder.mutation({
      query: (body) => ({
        url: "user-roles/create",
        method: "POST",
        body,
      }),
    }),
    getAllModules: builder.query<
      [],
      {
        channel_id?: number;
      }
    >({
      query: ({ channel_id }) => {
        const queryParams = {
          url: "module",
          params: { channel_id: 1 },
        };

        if (channel_id) {
          queryParams.params.channel_id = channel_id;
        }

        return queryParams;
      },
    }),
    getWarehousesByUser: builder.query<
      [],
      {
        user_id?: number;
      }
    >({
      query: ({ user_id }) => {
        const queryParams = {
          url: "common/warehouse-list",
          params: { user_id },
        };

        return queryParams;
      },
    }),
    getCustomerByUser: builder.query<
      [],
      {
        user_id?: number;
      }
    >({
      query: ({ user_id }) => {
        const queryParams = {
          url: "common/customer-list",
          params: { user_id },
        };

        return queryParams;
      },
    }),
    getAllProductByWarehouseIds: builder.query<
      any, // Change to the expected response type
      { warehouseIds?: string[] }
    >({
      query: ({ warehouseIds }) => ({
        url: "common/product-list/warehouse",
        method: "POST",
        body: { warehouseIds },
      }),
    }),

    getCustomerUserList: builder.query<
      [],
      {
        user_id?: number;
      }
    >({
      query: ({ user_id }) => {
        const queryParams = {
          url: "common/customer-user-list",
          params: { user_id },
        };

        return queryParams;
      },
    }),
    getProductDetailByWarehouse: builder.query<
      [],
      {
        product_id?: number;
        warehouse_id?: number;
      }
    >({
      query: ({ product_id, warehouse_id }) => {
        const queryParams = {
          url: "common/warehouse-list/product",
          params: { product_id, warehouse_id },
        };

        return queryParams;
      },
    }),
    getProductListByWarehouse: builder.query<
      [],
      {
        warehouse_id?: number;
      }
    >({
      query: ({ warehouse_id }) => {
        const queryParams = {
          url: "common/warehouse-product",
          params: { warehouse_id },
        };

        return queryParams;
      },
    }),
    getAllCustomerTypes: builder.query<[], void>({
      query: () => "common/customer-type",
    }),
    getAllBaseUnitList: builder.query<[], void>({
      query: () => "common/base-unit-list",
    }),
    getAllMainWarehouseList: builder.query<[], void>({
      query: () => "common/main-warehouse-list",
    }),
    getAllUnitTypeByBaseUnit: builder.query<
      [],
      {
        unit_id?: number;
      }
    >({
      query: ({ unit_id }) => {
        const queryParams = {
          url: `common/unit-type-list/${unit_id}`,
        };

        return queryParams;
      },
    }),
    getAllProductsByTripProposal: builder.query<
      [],
      {
        trip_id?: number;
        warehouse_id?: number;
      }
    >({
      query: ({ trip_id, warehouse_id }) => {
        const queryParams = {
          url: `common/trip-proposal-product-list/${trip_id}/${warehouse_id}`,
        };

        return queryParams;
      },
    }),
    getAllProductsByTripSo: builder.query<
      [],
      {
        trip_id?: number;
        warehouse_id?: number;
      }
    >({
      query: ({ trip_id, warehouse_id }) => {
        const queryParams = {
          url: `common/trip-product-list/sale-order/${trip_id}/${warehouse_id}`,
        };

        return queryParams;
      },
    }),

    getAllPromotionNameList: builder.query<
      [],
      {
        customer_category_id?: number | string;
        way_id?: number | string;
      }
    >({
      query: ({ customer_category_id, way_id }) => {
        const queryParams = {
          url: `common/promotion-list/${customer_category_id}/${way_id}`,
        };

        return queryParams;
      },
    }),
    getAllPromotionProductList: builder.query<
      [],
      {
        sale_promotion_id?: number | string;
      }
    >({
      query: ({ sale_promotion_id }) => {
        const queryParams = {
          url: `common/promotion-product-list/${sale_promotion_id}`,
        };

        return queryParams;
      },
    }),
    getSalePromotionIsEligible: builder.query<
      [],
      {
        id?: number | string;
      }
    >({
      query: ({ id }) => {
        const queryParams = {
          url: `common/sale-promotion/check/${id}`,
        };

        return queryParams;
      },
    }),
    getAllCreditTypes: builder.query<[], void>({
      query: () => "common/credit-type",
    }),
    getAllSaleType: builder.query<[], void>({
      query: () => "common/sale-type",
    }),
    getAllReturnReason: builder.query<[], void>({
      query: () => "common/return-reason",
    }),
    getAllPaymentTypes: builder.query<[], void>({
      query: () => "common/payment-type",
    }),
    getAllPaymentMethod: builder.query<[], void>({
      query: () => "common/payment-method",
    }),
    getAllProductTypes: builder.query<[], void>({
      query: () => "common/product-type",
    }),
    getAllSKU: builder.query<[], void>({
      query: () => "common/sku",
    }),
    getAllUnitTypes: builder.query<[], void>({
      query: () => "common/unit-types",
    }),

    getAllReferenceTypes: builder.query<[], void>({
      query: () => "common/reference-types",
    }),

    getAllStates: builder.query<[], void>({
      query: () => "common/location",
    }),
    getAllWayManagement: builder.query<[], void>({
      query: () => "common/way-management",
    }),
    getAllSubModulesByModuleId: builder.mutation({
      query: (body) => ({
        url: "sub-module?limit=1000",
        method: "POST",
        body,
      }),
    }),
    getCustomerAllCreditLimits: builder.query<
      [],
      {
        customerId: string | number;
      }
    >({
      query: ({ customerId }) => `common/customer-credit-limit/${customerId}`,
      providesTags: ["CustomerHistory"],
    }),
    getAllWayWarehouse: builder.query<
      [],
      {
        way_id: string | number;
      }
    >({
      query: ({ way_id }) => `common/warehouse-list-way/${way_id}`,
    }),
    getAllVehicleByTrip: builder.query<
      [],
      {
        trip_id: string | number;
      }
    >({
      query: ({ trip_id }) => `common/trip-proposal-vehicle/${trip_id}`,
    }),
    getAllPaymentTerms: builder.query<
      [],
      {
        status?: any;
      }
    >({
      query: ({ status = 1 }) => `common/payment-terms/${status}`,
    }),
    getAllProductPriceHistory: builder.query<
      [],
      {
        productId: string | number;
      }
    >({
      query: ({ productId }) => `common/product-history/${productId}`,
      providesTags: ["PriceHistory"],
    }),

    getStandardAppModuleSchema: builder.query<
      [],
      {
        channelId: string | number;
      }
    >({
      query: ({ channelId = -1 }) => `common/standard-modules/${channelId}`,
      providesTags: ["StandardAppSchema"],
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllUserRolesQuery,
  useGetAllModulesQuery,
  useGetWarehousesByUserQuery,
  useGetCustomerByUserQuery,
  useGetCustomerUserListQuery,
  useLazyGetAllModulesQuery,
  useLazyGetProductDetailByWarehouseQuery,
  useLazyGetProductListByWarehouseQuery,
  useGetAllStatesQuery,
  useGetAllMainWarehouseListQuery,
  useCreateUserRoleMutation,
  useGetAllPaymentTermsQuery,
  useGetAllReferenceTypesQuery,
  useGetAllPaymentTypesQuery,
  useGetAllPaymentMethodQuery,
  useGetAllProductTypesQuery,
  useGetAllSKUQuery,
  useGetAllUnitTypesQuery,
  useGetAllCreditTypesQuery,
  useGetAllSubModulesByModuleIdMutation,
  useGetAllCustomerTypesQuery,
  useGetCustomerAllCreditLimitsQuery,
  useGetAllProductPriceHistoryQuery,
  useGetAllReturnReasonQuery,
  useGetAllSaleTypeQuery,
  useGetAllBaseUnitListQuery,
  useLazyGetAllWayWarehouseQuery,
  useLazyGetAllUnitTypeByBaseUnitQuery,
  useLazyGetSalePromotionIsEligibleQuery,
  useLazyGetAllProductByWarehouseIdsQuery,
  useLazyGetAllProductsByTripProposalQuery,
  useLazyGetAllVehicleByTripQuery,
  useLazyGetAllProductsByTripSoQuery,
  useLazyGetAllPromotionNameListQuery,
  useLazyGetAllPromotionProductListQuery,
  useGetStandardAppModuleSchemaQuery,
  useGetAllWayManagementQuery,
} = commonApi;
