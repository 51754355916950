import { useState } from "react";
import DatePicker from "react-datepicker";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

interface CustomDateFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  noLabel?: boolean;
  placeHolder?: string;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  colSpan?: number;
  borderColor?: string;
  readOnly?: boolean;
  control: Control;
  required?: boolean;
  classNames?: string;
  iconClassNames?: string;
  minDate?: Date;
  maxDate?: Date;
}

const CustomDateField: React.FC<CustomDateFieldProps> = ({
  name,
  label,
  disabled,
  noLabel,
  placeHolder,
  register,
  errors,
  colSpan,
  borderColor,
  readOnly,
  control,
  required,
  classNames,
  iconClassNames,
  maxDate,
  minDate,
}) => {
  const isValidDate = (date: any) => !isNaN(new Date(date).getTime());

  return (
    <div
      style={{
        gridColumn: colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
      }}
      className={`flex flex-col p-2 ${classNames}`}
    >
      {!noLabel ? (
        <label
          className="mb-2 text-gray-700"
          style={{ textTransform: "capitalize" }}
          htmlFor="name"
        >
          {label || name}
          {required ? (
            <span className="ml-[0.15rem] text-red-600 text-md">&#42;</span>
          ) : null}
        </label>
      ) : null}
      <div
        // onClick={handleClick}
        className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600"
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value, ref } }) => {
            // Ensure that the value is a valid Date or null
            const selectedDate = isValidDate(value) ? new Date(value) : null;

            return (
              <DatePicker
                portalId="root-portal"
                disabled={disabled}
                dateFormat={"dd MMM yyyy"}
                placeholderText={
                  placeHolder
                    ? placeHolder
                    : `Enter ${label?.toLowerCase() || name}`
                }
                onBlur={onBlur}
                onChange={onChange}
                {...register}
                wrapperClassName="w-full"
                className={`px-2 cursor-pointer w-full py-3 focus:ring-1 ring-primary focus:outline-none border-[0.5px] rounded ${
                  borderColor ? borderColor : "border-primary-light"
                }`}
                selected={selectedDate}
                showYearDropdown
                scrollableYearDropdown // ✅ Enables scrolling for easier selection
                yearDropdownItemNumber={100}
                minDate={minDate}
                maxDate={maxDate}
              />
            );
          }}
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="currentColor"
          className={`absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3 ${iconClassNames}`}
        >
          <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
        </svg>
      </div>
      {errors[name] && (
        <div className="mt-2 font-[300] italic text-red-600">
          {/* @ts-ignore */}
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default CustomDateField;
