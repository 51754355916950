import AuthComponent from "@components/auth/component";
import BackButton from "@components/buttons/BackButton";
import CustomButton from "@components/buttons/CustomButton";
import CustomInputField from "@components/form/CustomInputField";
import CustomSelectField from "@components/form/CustomSelectField";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import PageWrapper from "@components/layouts/PageWrapper";
import { Toast } from "@helpers/popups/Toast";
import {
  bankInformationSchema,
  ZBankInformation,
} from "@helpers/validation-schemas/bank-information/bank.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useErrorHandling } from "@hooks/useErrorHandling";
import {
  useGetBankInformationQuery,
  useUpdateBankInformationMutation,
} from "@states/bank-information/bank-information.api";
import { AppDispatch } from "@states/store";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { BANK_INFORMATION } from "@utils/constants/bank-information.constants";
import { castTypeArr } from "@utils/general/cast-type";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function BankInformationEditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { data: bankData } = useGetBankInformationQuery({
    id: id!,
  });
  const [updateBankInformation, { isLoading, isSuccess, error: updateError }] =
    useUpdateBankInformationMutation();
  const defaultValues = useMemo(() => {
    //@ts-ignore
    console.log('BANK DEF', bankData?.data);
    //@ts-ignore
    return bankData?.data;
  }, [bankData]);
  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    formState,
  } = useForm<any>({
    defaultValues,
    resolver: zodResolver(bankInformationSchema),
  });
  useEffect(() => {
    //@ts-ignore
    if (bankData?.data?.length) {
      reset({
        //@ts-ignore
        description: bankData.data[0].description,
        //@ts-ignore
        payment_method_name: bankData.data[0].payment_method_name,
        //@ts-ignore
        status: bankData.data[0].status, // Ensure status is properly set
      });
    }
  }, [bankData, reset]);

  useErrorHandling(...castTypeArr<TFailResponse>([updateError]));

  const onSubmit: SubmitHandler<ZBankInformation> = async (data: any) => {
    try {
      setSubmitLoading(true);
      const res = await updateBankInformation({
        id,
        body: data,
      });

      const successData = "data" in res ? res.data : null;
      const { success, message } = successData as TSuccessResponse;

      if (success || isSuccess) {
        await Toast.fire({
          title: message,
          icon: "success",
        }).then(() => setSubmitLoading(false));

        navigate("/bank-information");
      }
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  return (
    <AuthComponent
      action="create"
      module="Bank Information"
      subModule="Bank Information"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <BackButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
            {BANK_INFORMATION.EDIT_NEW_TITLE}
          </h3>
          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <CustomInputField
              label="Payment Method"
              errors={formState.errors}
              name={"payment_method_name"}
              placeHolder="Enter Payment Method"
              register={register}
              //@ts-ignore
              control={control}
              //@ts-ignore
              defaultValue={bankData?.data[0].payment_method_name}
              required
            />

            <CustomSelectField
              errors={formState.errors}
              name="status"
              label="Status"
              control={control}
              options={[
                { label: "Active", value: 1 },
                { label: "Inactive", value: 0 },
              ]}
              value={1}
            />
          </div>

          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <CustomTextAreaField
              label="Description"
              key={"description"}
              errors={formState.errors}
              colSpan={3}
              name="description"
              register={register}
            />
          </div>

          <div className="ml-2 mt-14">
            <CustomButton
              isLarge
              title="Cancel"
              color="white"
              textColor="gray.800"
              className="mr-10"
              handleClick={() => navigate(-1)}
            />
            <CustomButton
              handleClick={handleSubmit(onSubmit)}
              isLarge
              title={isLoading ? "Loading..." : "Submit"}
              type="submit"
            />
          </div>
        </form>
      </PageWrapper>
    </AuthComponent>
  );
}
