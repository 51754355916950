import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { TRIP_MANAGEMENT } from "@utils/constants/trip/trip.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const tripApi = createApi({
  reducerPath: "tripApi",
  tagTypes: ["Trip"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllTrips: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        product_tag_name?: string;
        search?: string;
        trip_code?: string;

        from_date?: string;
        to_date?: string;
        region_id?: string;
        status?: string | number;
        trip_id?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        product_tag_name,
        search,
        from_date,
        to_date,
        region_id,
        trip_id,
        trip_code,
        status,
      }) => {
        const queryParams = {
          url: TRIP_MANAGEMENT.GET_ALL,
          params: { page, limit },
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (trip_id) {
          //@ts-ignore
          queryParams.params.trip_id = trip_id;
        }

        if (region_id) {
          //@ts-ignore
          queryParams.params.region_id = region_id;
        }
        if (product_tag_name) {
          //@ts-ignore
          queryParams.params.product_tag_name = product_tag_name;
        }
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (trip_code) {
          //@ts-ignore
          queryParams.params.trip_code = trip_code;
        }

        return queryParams;
      },
    }),

    getAllTripApprovals: builder.query<
        [],
        {
          page?: number;
          limit?: number;
          product_tag_name?: string;
          search?: string;
          trip_code?: string;

          from_date?: string;
          to_date?: string;
          region_id?: string;
          status?: string | number;
          trip_id?: string;
        }
    >({
      query: ({
                page = 1,
                limit = 10,
                product_tag_name,
                search,
                from_date,
                to_date,
                region_id,
                trip_id,
                trip_code,
                status,
              }) => {
        const queryParams = {
          url: TRIP_MANAGEMENT.GET_ALL_APPROVAL,
          params: { page, limit },
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (trip_id) {
          //@ts-ignore
          queryParams.params.trip_id = trip_id;
        }

        if (region_id) {
          //@ts-ignore
          queryParams.params.region_id = region_id;
        }
        if (product_tag_name) {
          //@ts-ignore
          queryParams.params.product_tag_name = product_tag_name;
        }
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (trip_code) {
          //@ts-ignore
          queryParams.params.trip_code = trip_code;
        }

        return queryParams;
      },
    }),
    getTrip: builder.query<any, { id: string | number }>({
      query: ({ id }) => TRIP_MANAGEMENT.GET_ONE + id,
    }),
    createTrip: builder.mutation({
      query: (body) => ({
        url: TRIP_MANAGEMENT.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateTripApproval: builder.mutation({
      query: ({ id, body }) => ({
        url: `${TRIP_MANAGEMENT.APPROVAL_UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteTrip: builder.mutation({
      query: (id) => ({
        url: `${TRIP_MANAGEMENT.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllTripsQuery,
  useGetAllTripApprovalsQuery,
  useGetTripQuery,
  useCreateTripMutation,
  useUpdateTripApprovalMutation,
  useDeleteTripMutation,
} = tripApi;
