import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { baseQueryWithReauth } from "@states/auth/auth.api";
import { BANK_INFORMATION } from "@utils/constants/bank-information.constants";

export const bankInformationApi = createApi({
  reducerPath: "bankInformationApi",
  tagTypes: ["BankInformation"],
  baseQuery: baseQueryWithReauth,
  // baseQuery: fetchBaseQuery({
  //     baseUrl: getBaseUrl(),
  // }),
  endpoints: (builder) => ({
    getAllBankInformation: builder.query<[], { page?: number; limit?: number }>(
      {
        query: ({ page = 1, limit = 10 }) => {
          const queryParams = {
            url: BANK_INFORMATION.GET_ALL,
            params: { page, limit },
          };

          return queryParams;
        },
      }
    ),
    createBankInformation: builder.mutation({
      query: (body) => ({
        url: BANK_INFORMATION.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateBankInformation: builder.mutation({
      query: ({ id, body }) => ({
        url: `${BANK_INFORMATION.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteBankInformation: builder.mutation({
      query: (id) => ({
        url: `${BANK_INFORMATION.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
    // getBankInformation: builder.mutation({
    //   query: (id) => ({
    //     url: `${BANK_INFORMATION.GET_ONE}${id}`,
    //     method: "GET",
    //   }),
    // }),
    getBankInformation: builder.query<[], { id: string | number }>({
      query: ({ id }) => {
        return `${BANK_INFORMATION.GET_ONE}${id}`;
      },
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllBankInformationQuery,
  useGetBankInformationQuery,
  useCreateBankInformationMutation,
  useUpdateBankInformationMutation,
  useDeleteBankInformationMutation,
} = bankInformationApi;
