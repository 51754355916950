import useGetPermission from "@hooks/common/useGetPermission";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  children: ReactNode;
  subModule: string;
  module: string;
  action: string;
  page?: boolean;
}

function AuthComponent({ children, action, module, subModule, page }: Props) {
  const { hasAccess } = useGetPermission();

  if (!hasAccess(action, module, subModule)) {
    if (page) {
      return <Navigate to={"/access-denied"} />;
    } else {
      return null;
    }
  }

  return <>{children}</>;
}

export default AuthComponent;
