import React, { useEffect, useState } from "react";
import { UseFormRegister, FieldErrors } from "react-hook-form";

interface CustomInputFieldProps {
  inputType?: string;
  colSpan?: number;
  name: string;
  label?: string;
  disabled?: boolean;
  noLabel?: boolean;
  placeHolder?: string;
  register: UseFormRegister<any>;
  watch: any;
  setValue: any;
  errors: FieldErrors<any>;
  borderColor?: string;
  value?: string;
  showLabelOptional?: boolean;
  isFile?: boolean;
  isViewPdf?: boolean; // Added to check if view pdf button should be displayed
}

const CustomFileField: React.FC<CustomInputFieldProps> = ({
  inputType,
  name,
  label,
  disabled,
  noLabel,
  placeHolder,
  register,
  setValue,
  watch,
  errors,
  colSpan,
  borderColor,
  value,
  showLabelOptional,
  isFile,
  isViewPdf, // Accessing the new prop
}) => {
  const [fileValue, setFileValue] = useState<string | undefined>(value);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(value);
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  useEffect(() => {
    setFileValue(value);
    setPreviewUrl(value);
  }, [value]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleViewPdf = (event: React.MouseEvent) => {
    event.preventDefault();
    if (previewUrl) window.open(previewUrl, "_blank");
  };

  const handleRemove = () => {
    setValue(name); 
    setPreviewUrl(undefined);
  }

  console.log('file')

  return (
    <div style={{
        gridColumn: colSpan !== 6 ? `auto / span ${colSpan || "2"}` : "1 / -1",
      }} className="flex flex-col p-2">
      {label && <label className="mb-2 text-gray-700">{label}</label>}
      <input
        type="file"
        className="hidden"
        id={name}
        {...register(name, { onChange: handleFileChange })}
      />
      <div
        className="border border-gray-300 rounded-md p-3 cursor-pointer flex justify-center items-center"
        onClick={() => document.getElementById(name)?.click()}
      >
        {previewUrl ? (
          isViewPdf ? (
            <embed className="w-full h-72 rounded-md" src={previewUrl} type="application/pdf" />
          ) : (
            <img className="w-full h-72 object-cover rounded-md" src={previewUrl} alt="Preview" />
          )
        ) : (
          <span className="text-gray-500">Click to upload</span>
        )}
      </div>
      {previewUrl && (
        <div className="flex justify-between mt-2">
          {isViewPdf && (
            <button onClick={handleViewPdf} className="text-blue-500">View PDF</button>
          )}
          <button
            onClick={handleRemove}
            className="text-red-500"
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomFileField;
