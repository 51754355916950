import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const officeUserRoleApi = createApi({
    reducerPath: 'officeUserRoleApi',
    tagTypes: ['OfficeUserRole'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllOfficeUserRoles: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({
                page = 1,  // Default value for page
                limit = 10 // Default value for limit
            }) => ({
                url: 'office-users/office-user-roles',
                params: { page, limit },
            }),
        }),

    }),
    keepUnusedDataFor: 0,
});

export const {
    useGetAllOfficeUserRolesQuery,
} = officeUserRoleApi;
