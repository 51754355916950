import React from 'react';
import { ActionButtonProps } from '../../types/general/TActionButtonProps';

const InfoButton: React.FC<ActionButtonProps> = ({
    fill,
    id,
    actionHandler,
}) => {
    return (
        <svg
            onClick={() => actionHandler(id)}
            style={{
                marginRight: '8px',
                cursor: 'pointer',
            }}
            width="24"
            height="24"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="15"
                cy="15"
                r="12.25"
                stroke="#0054A6"
                stroke-width="1.5"
            />
            <path
                d="M15.222 11.75C14.9347 11.75 14.6591 11.8451 14.456 12.0144C14.2528 12.1837 14.1387 12.4133 14.1387 12.6528V21.6806C14.1387 21.92 14.2528 22.1496 14.456 22.3189C14.6591 22.4882 14.9347 22.5833 15.222 22.5833C15.5093 22.5833 15.7849 22.4882 15.988 22.3189C16.1912 22.1496 16.3053 21.92 16.3053 21.6806V12.6528C16.3053 12.4133 16.1912 12.1837 15.988 12.0144C15.7849 11.8451 15.5093 11.75 15.222 11.75Z"
                fill="#0054A6"
            />
            <path
                d="M15.2189 10.0141C15.9369 10.0141 16.5189 9.43203 16.5189 8.71406C16.5189 7.99609 15.9369 7.41406 15.2189 7.41406C14.501 7.41406 13.9189 7.99609 13.9189 8.71406C13.9189 9.43203 14.501 10.0141 15.2189 10.0141Z"
                fill="#0054A6"
            />
        </svg>
    );
};

export default InfoButton;
