import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { OFFICE_USER } from "@utils/constants/office-user.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const officeUserApi = createApi({
  reducerPath: "officeUserApi",
  tagTypes: ["OfficeUser"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllOfficeUsers: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        username?: string;
        status?: number;
        user_role_id?: number;
        type?: number;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        username,
        status,
        user_role_id,
        type,
      }) => {
        const queryParams = {
          url: OFFICE_USER.GET_ALL,
          params: { page, limit },
        };

        if (username) {
          //@ts-ignore
          queryParams.params.username = username;
        }

        if (type) {
          //@ts-ignore
          queryParams.params.type = type;
        }
        if (status) {
          //@ts-ignore
          queryParams.params.status = status;
        }

        if (user_role_id) {
          //@ts-ignore
          queryParams.params.user_role_id = user_role_id;
        }

        return queryParams;
      },
    }),
    createOfficeUser: builder.mutation({
      query: (body) => ({
        url: "office-users/create",
        method: "POST",
        body,
      }),
    }),
    updateOfficeUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `office-users/update/${id}`,
        method: "PATCH",
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: ({ id, body }) => ({
        url: `${OFFICE_USER.CHANGE_PASSWORD}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteOfficeUser: builder.mutation({
      query: (id) => ({
        url: `office-users/delete/${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllOfficeUsersQuery,
  useCreateOfficeUserMutation,
  useChangePasswordMutation,
  useUpdateOfficeUserMutation,
  useDeleteOfficeUserMutation,
} = officeUserApi;
