import React, { ReactNode } from "react";
import AddButton from "../buttons/CustomButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../buttons/BackButton";

interface PageWrapperProps {
  children: ReactNode;
  title?: string;
  addPath?: string;
  addButtonTitle?: string;
  showBackButton?: boolean;
  hasCreatePermission?: boolean;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  title,
  addPath,
  addButtonTitle,
  showBackButton,
  hasCreatePermission = false,
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-md p-8 min-h-[60vh]">
      {showBackButton && (
        <div className="mt-6">
          <BackButton />
        </div>
      )}
      <div className="flex items-center justify-between mb-6">
        <h3 className="font-bold text-gray-700 uppercase">{title}</h3>
        {addPath && hasCreatePermission && (
          <AddButton
            handleClick={() => navigate(addPath)}
            title={addButtonTitle || "Add"}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default PageWrapper;
