import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { BUSINESS_UNIT } from "../../utils/constants/business-unit.constants";
import {baseQueryWithReauth} from "@states/auth/auth.api";

export const businessUnitApi = createApi({
  reducerPath: "businessUnitApi",
  tagTypes: ["BusinessUnit"],
  baseQuery: baseQueryWithReauth,
  // baseQuery: fetchBaseQuery({
  //   baseUrl: getBaseUrl(),
  // }),
  endpoints: (builder) => ({
    getAllBusinessUnits: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
        customer_id?: string | number;
        region_way_id?: string | number;
        customer_type_id?: string | number;
      }
    >({
      query: ({
        page = 1,
        limit = 100,
        search,
        customer_id,
        region_way_id,
        customer_type_id,
      }) => {
        const queryParams = {
          url: BUSINESS_UNIT.GET_ALL,
          params: { page, limit },
        };

        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        if (customer_type_id) {
          //@ts-ignore
          queryParams.params.customer_type_id = customer_type_id;
        }
        if (customer_id) {
          //@ts-ignore
          queryParams.params.customer_id = customer_id;
        }
        if (region_way_id) {
          //@ts-ignore
          queryParams.params.region_way_id = region_way_id;
        }

        return queryParams;
      },
    }),
    createBusinessUnit: builder.mutation({
      query: (body) => ({
        url: BUSINESS_UNIT.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateBusinessUnit: builder.mutation({
      query: ({ id, body }) => ({
        url: `${BUSINESS_UNIT.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteBusinessUnit: builder.mutation({
      query: (id) => ({
        url: `${BUSINESS_UNIT.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllBusinessUnitsQuery,
  useCreateBusinessUnitMutation,
  useUpdateBusinessUnitMutation,
  useDeleteBusinessUnitMutation,
} = businessUnitApi;
