export const GOOD_RETURN_NOTE = {
    BASE_PATH: '/good-return/good-return-note/',
    BASE_PATH_RECEIVE: '/good-return/good-receive-note/',
    CONVERT_TO_RECEIVE_NOTEE(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/convert-to-receive-note';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_PATH_RECEIVE() {
        return this.BASE_PATH_RECEIVE + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Good Return Note',
    ADD_BUTTON_TITLE_RECEIVE: 'Add New Good Receive Note',
    LIST_PAGE_TITLE: 'Good Return Note List',
    LIST_PAGE_TITLE_RECEIVE: 'Good Return Receive List',
    EDIT_PAGE_TITLE: 'Edit Good Return Note',
    GET_ALL: 'goods-return/goods-return-note',
    GET_ALL_RECEIVE: 'goods-return/goods-receive-note',
    GET_ONE: 'goods-return/goods-return-note/edit/',
    GET_ONE_RECEIVE: 'goods-return/goods-receive-note/edit/',
    FIND_BY_INVOICE: 'goods-return/goods-return-note/search/invoice',
    CREATE: 'goods-return/goods-return-note/create',
    CREATE_RECEIVE: 'goods-return/goods-receive-note/create',
    UPDATE: 'goods-return/goods-return-note/update/',
    UPDATE_RECEIVE: 'goods-return/goods-receive-note/update/',
    DELETE: 'goods-return/goods-return-note/delete/',
    DELETE_RECEIVE: 'goods-return/goods-receive-note/delete/',
    ADD_NEW_TITLE: 'Add New Good Return Note',
    GET_ONE_RECEIVE_FOR_PRINT: 'goods-return/goods-receive-note/print/',
};
