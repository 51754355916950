import useGetPermission from "@hooks/common/useGetPermission";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  children: ReactNode;
  module: string;
}

function AuthSidebar({ children, module }: Props) {
  const { hasModuleAccess, hasSubModuleAccess } = useGetPermission();

  if (!hasModuleAccess(module)) return null;

  return <>{children}</>;
}

export default AuthSidebar;
