function AccessDeniedPage() {
  return (
    <div className="flex items-center justify-center h-screen bg-white ">
      <div className="">
        <h1 className="text-3xl font-bold text-primary dark:text-primary-400">
          Access Denied
        </h1>
        <p className="text-gray-800 dark:text-gray-300 mt-4">
          You do not have permission to view this page.
        </p>
        <p className="text-gray-800 dark:text-gray-300">
          Please contact your administrator.
        </p>
        <button
          onClick={() => (window.location.href = "/")}
          className="mt-6 bg-primary hover:bg-primary-700 text-white font-bold py-2 px-4 rounded-lg"
        >
          Go Home
        </button>
      </div>
    </div>
  );
}

export default AccessDeniedPage;
