import useGetPermission from "@hooks/common/useGetPermission";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  children: ReactNode;
  module: string;
  subModule: string;
}

function AuthSidebarChild({ children, module, subModule }: Props) {
  const { hasSubModuleAccess } = useGetPermission();

  if (!hasSubModuleAccess(module, subModule)) return null;

  return <>{children}</>;
}

export default AuthSidebarChild;
