import BackButton from "@components/buttons/BackButton";
import CustomButton from "@components/buttons/CustomButton";
import CustomDateField from "@components/form/CustomDateField";
import CustomInputField from "@components/form/CustomInputField";
import PageWrapper from "@components/layouts/PageWrapper";
import { Toast } from "@helpers/popups/Toast";
import { TPrimaryInvoice } from "@helpers/validation-schemas/primary-sale-order/invoice.schema";

import {
  ZPaymentReceive,
  paymentReceiveSchema,
} from "@helpers/validation-schemas/consignment/payment-receive.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { addFormValues, resetFormValues } from "@states/common/common.slice";
import { useCreatePrimaryPaymentReceiveMutation } from "@states/primary-sale-order/primary-payment-receive.api";
import { useCreateConsignmentPaymentReceiveMutation } from "@states/consignment/consignment-payment-receive.api";
import { CONSIGNMENT_PAYMENT_RECEIVE } from "@utils/constants/consignment/consignment-payment-receive.constants";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@states/store";
import useGetAllPaymentMethod from "@hooks/common/useGetAllPaymentMethod";
import CustomSelectField from "@components/form/CustomSelectField";
import CustomFileField from "@components/form/CustomFileField";
import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { formatDBDate } from "@utils/general/format-db-date";
import AuthComponent from "@components/auth/component";
import CustomTextAreaField from "@components/form/CustomTextAreaField";

const ConsignmentPaymentReceiveAddPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { formValues } = useSelector((state: RootState) => state.common);
  const { paymentMethodData } = useGetAllPaymentMethod();
  const {
    consignment_invoice_id,
    consignment_invoice_code,
    balance,
    invoice_date,
    due_date,
    warehouse_id,
    payment_type_id,
    grand_total_amount,
  }: any = location?.state?.carriedFormValues;

  const defaultValues: any = {
    consignment_invoice_id,
    consignment_invoice_code,
    invoice_date,
    payment_receive_date: new Date(invoice_date),
    // new Date().toISOString()
    payment_receive_amount: 0,
    invoiceData: {
      balance: balance || 0,
      due_date,
      warehouse_id,
      payment_type_id,
      grand_total_amount,
    },
  };

  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<ZPaymentReceive>({
    defaultValues,
    resolver: zodResolver(paymentReceiveSchema),
  });

  const [createConsignmentPaymentReceive, { data, isSuccess }] =
    useCreateConsignmentPaymentReceiveMutation();

  useEffect(() => {
    if (isSuccess && data) {
      Toast.fire({
        title: data.message,
        icon: "success",
      });

      navigate(CONSIGNMENT_PAYMENT_RECEIVE.BASE_PATH, {
        state: {
          reload: true,
        },
      });
    }
  }, [isSuccess, data, navigate, Toast]);

  useEffect(() => {
    reset(defaultValues);

    dispatch(addFormValues(defaultValues));
  }, [location, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      dispatch(addFormValues(value));
    });

    return () => {
      subscription.unsubscribe();
      dispatch(resetFormValues());
    };
  }, [watch, dispatch]);

  const onSubmit: SubmitHandler<ZPaymentReceive> = async (data) => {
    try {
      const { invoiceData, file, ...rest } = data;
      let trimData = {
        consignment_invoice_id: rest.consignment_invoice_id,
        payment_receive_date: formatDBDate(rest.payment_receive_date),
        payment_receive_amount: rest.payment_receive_amount,
        payment_method_id: rest.payment_method_id,
        description: rest.description,
        // @ts-ignore
        balance: invoiceData.balance,
      };
      const formData = new FormData();

      if (file[0]) {
        formData.append("file", file[0]);
      }
      for (const [key, value] of Object.entries(trimData)) {
        //@ts-ignore
        formData.append(key, value);
      }
      const response = await fetch(
        `${getBaseUrl()}/consignment/payment-receive/create`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY
            )}`, // Add token here
          },
        }
      );

      const res = await response.json();

      if (res.success == false) {
        Toast.fire({
          title: res.message,
          icon: "error",
        });

        // setCreateLoading(false);

        return;
      }

      const { success, message } = res as TSuccessResponse;

      // setCreateLoading(false);

      if (success || isSuccess) {
        await Toast.fire({
          title: message,
          icon: "success",
        });

        navigate(CONSIGNMENT_PAYMENT_RECEIVE.BASE_PATH, {
          state: {
            reload: true,
          },
        });
      }

      //   await createConsignmentPaymentReceive(trimData);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  return (
    <AuthComponent
      action="create"
      module="Consignment"
      subModule="Payment Receive"
      page
    >
      <PageWrapper>
        <BackButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
            Make Payment Receive
          </h3>
          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <input type="hidden" {...register("consignment_invoice_code")} />
            <CustomInputField
              disabled
              label="Invoice Id"
              errors={errors}
              name={"consignment_invoice_code"}
              placeHolder="Enter invoice id"
              register={register}
            />
            <CustomDateField
              disabled
              errors={errors}
              name="invoiceData.due_date"
              label="Due Date"
              register={register}
              //@ts-ignore
              control={control}
            />
            <CustomDateField
              errors={errors}
              name="payment_receive_date"
              label="Payment Receive Date"
              register={register}
              //@ts-ignore
              control={control}
              minDate={invoice_date}
              maxDate={due_date}
            />
            <CustomSelectField
              errors={errors}
              name="payment_method_id"
              label="Payment Method"
              placeHolder="Select Payment Method"
              //@ts-ignore
              control={control}
              //@ts-ignore
              options={paymentMethodData?.map((item: any) => ({
                label: item.payment_method_name,
                value: item.payment_method_id,
              }))}
              required
            />

            <CustomInputField
              disabled
              inputType="number"
              label="Total Invoice Amount"
              errors={errors}
              name={"invoiceData.grand_total_amount"}
              placeHolder="Enter total invoice amount"
              register={register}
            />
            <CustomInputField
              disabled
              inputType="number"
              label="Balance"
              errors={errors}
              name={"invoiceData.balance"}
              placeHolder="Enter balance"
              register={register}
            />
            <CustomInputField
              inputType="number"
              label="Paid Amount"
              errors={errors}
              name={"payment_receive_amount"}
              placeHolder="Enter paid amount"
              register={register}
              max={balance}
              min={1}
            />
            <CustomFileField
              label="Attach File"
              placeHolder="Attach a file here"
              errors={errors}
              name="file"
              register={register}
              setValue={setValue}
              watch={watch}
              isViewPdf
            />
            <CustomTextAreaField
              // value={formValues?.description}
              key={"description"}
              errors={errors}
              colSpan={6}
              name="description"
              register={register}
            />
          </div>
          <div className="ml-2 mt-14">
            <CustomButton
              isLarge
              title="Cancel"
              color="white"
              textColor="gray.800"
              className="mr-10"
              handleClick={() => navigate(-1)}
            />
            <CustomButton
              handleClick={handleSubmit(onSubmit)}
              isLarge
              title={isSubmitting ? "Loading..." : "Submit"}
              disabled={isSubmitting}
              type="submit"
            />
          </div>
          {errors.root && (
            <div className="mt-6 text-red-500">{errors.root.message}</div>
          )}
        </form>
      </PageWrapper>
    </AuthComponent>
  );
};

export default ConsignmentPaymentReceiveAddPage;
