import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "@components/layouts/PageWrapper";
import BackButton from "@components/buttons/BackButton";
import SaleTargetAllEdit from "@components/extra/sale-target/SaleTargetAllEdit";
import SaleTargetProductEdit from "@components/extra/sale-target/SaleTargetProductEdit";
import {
  useGetSaleTargetQuery,
  useUpdateSaleTargetMutation,
} from "@states/sale-target/sale-target.api";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import AuthComponent from "@components/auth/component";
function ProductTargetEditPage() {
  const { id } = useParams();

  const { data: saleTargetData, isLoading } = useGetSaleTargetQuery({
    sales_target_id: id!,
  });

  const navigate = useNavigate();

  return (
    <AuthComponent
      action="edit"
      module="Sales Target"
      subModule="Product Target"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <BackButton />
        <h3 className="mb-4 ml-2 text-2xl font-semibold text-primary-dark uppercase">
          Edit Product Sale Target
        </h3>
        {/* <ul className="flex justify-start mt-5">
                <li className={target === 1 ? "border-blue-600 border-b-2" : ""}>
                    <h3
                        className="ml-2 text-lg font-semibold text-[#414B5A] px-5 cursor-pointer"
                        // onClick={changeTarget}
                        style={{ pointerEvents: "none", opacity: 0.9 }}
                    >
                        All Target
                    </h3>
                </li>
                <li className={target === 2 ? "border-blue-600 border-b-2" : ""}>
                    <h3
                        className="ml-2 text-lg font-semibold text-[#414B5A] px-5 cursor-pointer"
                        // onClick={changeTarget}
                        style={{ pointerEvents: "none", opacity: 0.9 }}
                    >
                        Product Target
                    </h3>
                </li>
            </ul> */}
        <hr className="mb-2" />

        <SaleTargetProductEdit saleTargetData={saleTargetData?.data} />
      </PageWrapper>
    </AuthComponent>
  );
}

export default ProductTargetEditPage;
