import DeleteButton from "@components/buttons/DeleteButton";
import EditButton from "@components/buttons/EditButton";
import { thousandSeperator } from "@utils/general/digit-separators";
import OutlinedStatus from "@components/status/OutlinedStatus";
import { formatDate } from "@utils/general/format-date";
import AuthComponent from "@components/auth/component";

export const consignmenttColumns = (
  editHandler: (id: string | number, status: string | number) => void,
  deleteHandler: () => void
) => [
  {
    name: "No",
    selector: (row: any) => (!isNaN(row.no) ? row.no + 1 : row.no),
    width: "10ch",
  },
  {
    name: "Consignment ID",
    selector: (row: any) => (
      <div className="italic font-semibold text-primary-dark">
        {row.consignment_code}
      </div>
    ),
    // width: "20ch",
    width: "200px",

    // sortable: true,
    // wrap: true,
  },
  {
    name: "Sales Date",
    selector: (row: any) =>
      row.sales_date && formatDate(new Date(row.sales_date)),
    // sortable: true,
    wrap: true,
    width: "15ch",
  },
  {
    name: "Customer Name",
    selector: (row: any) =>
      //@ts-ignore
      row.no !== "Totals:" && (
        <div className=" pl-3">
          {row.customer_name || row.customer_first_name}
        </div>
      ),
    // sortable: true,
    wrap: true,
    width: "20ch",
  },
  {
    name: "Distribution Region Name",
    selector: (row: any) => (
      //@ts-ignore
      <div className=" pl-3">{row.region_name}</div>
    ),
    // sortable: true,
    wrap: true,
    width: "20ch",
  },
  {
    name: "Way Name",
    selector: (row: any) => (
      //@ts-ignore
      <div className=" pl-3">{row.way_name}</div>
    ),
    // sortable: true,
    wrap: true,
    width: "20ch",
  },
  {
    name: "Business Unit Name",
    selector: (row: any) => (
      <div className=" pl-5">{row.business_unit_name}</div>
    ),
    // sortable: true,
    wrap: true,
    width: "20ch",
  },

  {
    name: "Status",
    selector: (row: any) =>
      //@ts-ignore
      row.no !== "Totals:" && (
        <OutlinedStatus
          width="w-[17ch]"
          color={
            row.status === 1
              ? "blue"
              : row.status === 3 || row.status === 2
              ? "green"
              : row.status === 4
              ? "red"
              : "blue"
          }
          title={
            row.status === 1
              ? "Open"
              : row.status === 2
              ? "Created Invoice"
              : row.status === 3
              ? "Approve & Open"
              : row.status === 4
              ? "Rejected"
              : "Closed"
          }
        />
      ),
    grow: 2,
    // sortable: true,
  },
  {
    name: "Grand Total Amount",
    selector: (row: any) =>
      //@ts-ignore
      thousandSeperator(Number(row.grand_total_amount) || 0),
    // thousandSeperator(row.grand_total_amount || 0),
    // sortable: true,
    width: "20ch",
  },
  {
    name: "Action",
    right: "true",
    selector: (row: any) =>
      //@ts-ignore
      !row.noAction ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AuthComponent
            action="edit"
            module="Consignment"
            subModule="Consignment"
          >
            <EditButton
              disabled={row.status == 2}
              id={row.consignment_id}
              actionHandler={() => editHandler(row.consignment_id, row.status)}
            />
          </AuthComponent>

          <AuthComponent
            action="delete"
            module="Consignment"
            subModule="Consignment"
          >
            <DeleteButton
              disabled={row.status == 2}
              id={row.consignment_id}
              actionHandler={deleteHandler}
            />
          </AuthComponent>
        </div>
      ) : null,
  },
];
