import CustomForm from "../../../components/form/CustomForm";
import PageWrapper from "../../../components/layouts/PageWrapper";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { unitFields } from "../../../helpers/fields/unit/unit.fields";
import { unitSchema } from "../../../helpers/validation-schemas/unit/unit.schema";
import { TUnit } from "../../../types/unit/TUnit";
import { useUpdateUnitOfMeasurementMutation } from "../../../states/unit/unit-of-measurement.api";
import { useErrorHandling } from "../../../hooks/useErrorHandling";
import { castTypeArr } from "../../../utils/general/cast-type";
import { TFailResponse } from "../../../types/general/TFailResponse";
import { removeKeys } from "../../../utils/general/removeKeys";
import { Toast } from "../../../helpers/popups/Toast";
import { UNIT_OF_MEASUREMENT } from "../../../utils/constants/unit-of-measurement.constants";
import { LoadingSpinner } from "../../../components/layouts/LoadingSpinner";
import { vehicleFields } from "@helpers/fields/trip/vehicle.fields";
import { VEHICLE_MANAGEMENT } from "@utils/constants/vehicle-management.constants";
import { vehicleSchema } from "@helpers/validation-schemas/trip/vehicle.schema";
import useGetOneVehicle from "@hooks/trip/useGetOneVehicle";
import { useUpdateVehicleMutation } from "@states/trip/vehicle.api";
import AuthComponent from "@components/auth/component";

const VehicleEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // const { unitOfMeasurement } = location.state as {
  //   unitOfMeasurement: TUnit;
  // };

  const { vehicleData, vehicleLoading, vehicleRefetch } = useGetOneVehicle(id!);

  const [updateVehicle, { isLoading, error: updateError, isSuccess }] =
    useUpdateVehicleMutation();
  useErrorHandling(...castTypeArr<TFailResponse>([updateError]));

  const fields = useMemo(() => {
    return vehicleFields();
  }, []);

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const res = await updateVehicle({
          id,
          body: { ...reqBody },
        });

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData;

        if (success || isSuccess) {
          Toast.fire({
            title: message,
            icon: "success",
          });

          navigate(VEHICLE_MANAGEMENT.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [updateVehicle, isSuccess, Toast, navigate]
  );

  return (
    <AuthComponent
      action="edit"
      module="Trip"
      subModule="Vehicle Management"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <CustomForm
          title="Edit Vehicle"
          schema={vehicleSchema}
          defaultValues={vehicleData}
          fields={fields}
          asyncDispatcher={asyncDispatcher}
        />
      </PageWrapper>
    </AuthComponent>
  );
};

export default VehicleEditPage;
