import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { WAREHOUSE_USER_ASSIGN } from "@utils/constants/warehouse-user-assign.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const warehouseUserAssignApi = createApi({
  reducerPath: "warehouseUserAssignApi",
  tagTypes: ["WarehouseUserAssign"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllWarehouseUserAssigns: builder.query<
      [],
      { page?: number; limit?: number; brand_name?: string }
    >({
      query: ({ page = 1, limit = 10, brand_name }) => {
        const queryParams = {
          url: WAREHOUSE_USER_ASSIGN.GET_ALL,
          params: { page, limit },
        };

        if (brand_name) {
          //@ts-ignore
          queryParams.params.brand_name = brand_name;
        }

        return queryParams;
      },
    }),
    getWarehouseUserAssign: builder.query<any, { userId: string | number }>({
      query: ({ userId }) => WAREHOUSE_USER_ASSIGN.GET_ONE + userId,
    }),
    createWarehouseUserAssign: builder.mutation({
      query: (body) => ({
        url: WAREHOUSE_USER_ASSIGN.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateWarehouseUserAssign: builder.mutation({
      query: ({ id, body }) => ({
        url: `${WAREHOUSE_USER_ASSIGN.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteWarehouseUserAsssign: builder.mutation({
      query: (id) => ({
        url: `${WAREHOUSE_USER_ASSIGN.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllWarehouseUserAssignsQuery,
  useGetWarehouseUserAssignQuery,
  useCreateWarehouseUserAssignMutation,
  useUpdateWarehouseUserAssignMutation,
  useDeleteWarehouseUserAsssignMutation,
} = warehouseUserAssignApi;
