export const CONSIGNMENT = {
  BASE_PATH: "/consignment/consignment/",
  CONVERT_TO_INVOICE_PATH(id: string | number) {
    return this.BASE_PATH + `${id}` + "/edit/convert-to-invoice";
  },
  ADD_PATH() {
    return this.BASE_PATH + "add";
  },
  ADD_BUTTON_TITLE: "Add New Consignment",
  LIST_PAGE_TITLE: "Consignment List",
  EDIT_PAGE_TITLE: "Edit Consignment",
  GET_ALL: "consignment",
  GET_ALL_APPROVAL: "consignment/approval",
  GET_ONE: "consignment/edit/",
  CREATE: "consignment/create",
  UPDATE: "consignment/update/",
  APPROVE: "/consignment/approval/update/",
  DELETE: "consignment/delete/",
  ADD_NEW_TITLE: "Add New Consignment",
};
