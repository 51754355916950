import { z } from "zod";

export const bankInformationSchema = z.object({
  payment_method_name: z
    .string({
      message: "Payment Method Name is required",
    })
    .nonempty({
      message: "Payment Method Name is required",
    }),
  description: z
    .string().optional(),
  status: z.union([z.string(), z.number()]).nullable().optional(),
});

export type ZBankInformation = z.infer<typeof bankInformationSchema>;
