import React from "react";
import DataTable from "react-data-table-component";
import ItemListPagination from "../trip/ItemListPagination";
import { thousandSeperator } from "@utils/general/digit-separators";

const PaymentReceiveItemListTable = (props: { data: any[] }) => {
  return (
    <DataTable
      conditionalRowStyles={[
        {
          when: (row) => row.product_code === "Totals:",
          style: {
            backgroundColor: "#f3f4f6",
            fontWeight: 500,
          },
        },
      ]}
      columns={[
        {
          name: "Invoice ID",
          selector: (row: any) =>
            row.secondary_sales_invoice_code ||
            row.trip_sale_invoice_code ||
            row.consignment_invoice_code,
        },
        {
          name: "Grand Total Amount",
          selector: (row: any) => row.grand_total_amount,
        },
        {
          name: "Paid Amount",
          selector: (row: any) =>
            thousandSeperator(row.payment_receive_amount || 0),
          wrap: true,
        },
        {
          name: "Balance",
          selector: (row: any) =>
            row.noAction ? null : thousandSeperator(row.balance || 0),
        },
        {
          name: "Received By",
          selector: (row: any) =>
            row.username || row.first_name || row.last_name || "-",
        },
      ]}
      data={props.data}
      striped
      responsive
      // pagination
      // paginationComponent={() => <ItemListPagination />}
    />
  );
};

export default PaymentReceiveItemListTable;
