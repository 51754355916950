import PageWrapper from "@components/layouts/PageWrapper";
import TableSkeleton from "@components/layouts/TableSkeleton";
import React, { useEffect, useState } from "react";
import usePagination from "@hooks/usePagination";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllSaleTargetsQuery,
  useDeleteSaleTargetMutation,
} from "../../../states/sale-target/sale-target.api";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { useErrorHandling } from "../../../hooks/useErrorHandling";
import { useDidMountEffect } from "../../../hooks/useDidMountEffect";
import { SALE_TARGET } from "../../../utils/constants/sale-target.constants";
import { TUnitOfConversion } from "../../../types/unit/TUnitOfConversion";
import { DeletePopup } from "../../../helpers/popups/DeletePopup";
import { TDeleteSuccesResponse } from "../../../types/general/TDeleteSuccessResponse";
import { castTypeArr } from "../../../utils/general/cast-type";
import { TFailResponse } from "../../../types/general/TFailResponse";
import { Toast } from "../../../helpers/popups/Toast";
import {
  AllTargetColumnNames,
  AllTargetColumns,
} from "../../../helpers/columns/sale-target/all-target.columns";
import { TSaleTarget } from "@type-defs/sale-target/TSaleTarget";
import CustomButton from "@components/buttons/CustomButton";
import AuthComponent from "@components/auth/component";
import useGetPermission from "@hooks/common/useGetPermission";

export default function AllTargetPage() {
  const { hasAccess } = useGetPermission();
  const { page, limit, handlePageChange, handlePerRowsChange } =
    usePagination();
  const navigate = useNavigate();
  const location = useLocation();
  const sales_target_type_id = 1;

  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);
  const {
    data,
    isFetching,
    error: getAllError,
    refetch,
  } = useGetAllSaleTargetsQuery({
    page,
    limit,
    sales_target_type_id,
    // @ts-ignore
    from_date,
    // @ts-ignore
    to_date,
  });

  const [deleteSaleTarget, { error: deleteError, isLoading }] =
    useDeleteSaleTargetMutation();

  useErrorHandling(...castTypeArr<TFailResponse>([getAllError, deleteError]));

  useDidMountEffect(() => {
    refetch();
  }, [page, limit]);

  useEffect(() => {
    if (location.state?.reload) {
      refetch();
    }
  }, [location.state?.reload]);

  const editHandler = (saleTarget: TSaleTarget) => {
    navigate(
      `${SALE_TARGET.BASE_PATH}all-target/${saleTarget.sales_target_id}/edit`,
      {
        state: {
          saleTarget,
        },
      }
    );
  };

  const deleteHandler = async (id: number | string) => {
    try {
      const { isConfirmed } = await DeletePopup.fire();

      if (isConfirmed) {
        const res = await deleteSaleTarget(id);

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TDeleteSuccesResponse;

        if (success) {
          Toast.fire({
            icon: "success",
            title: message,
          });

          refetch();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const applyFilter = () => {
    setFromDate(null);
    setToDate(null);
    refetch();
  };
  return (
    <AuthComponent
      action="view"
      module="Sales Target"
      subModule="All Target"
      page
    >
      <PageWrapper
        addPath={"/sale-target/all-target/add"}
        addButtonTitle="Create New Sale All Target"
        title="Sale All Target List"
        hasCreatePermission={hasAccess("create", "Sales Target", "All Target")}
      >
        <div className="flex items-end mb-5">
          <div className="mr-6 basis-[20%] flex flex-col">
            <label className="text-gray-700 " htmlFor="name">
              From Date
            </label>
            <div className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600">
              <DatePicker
                selected={from_date}
                // @ts-ignore
                onChange={(date) => setFromDate(format(date, "yyyy-MM-dd"))}
                wrapperClassName="w-full"
                className="ring-primary px-3 mt-[0.5rem] border-primary-light border-[0.5px] h-[34px] w-full block rounded"
                dateFormat={"yyyy-MMM-dd"}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="currentColor"
                className={`absolute w-6 h-6 transform -translate-y-1/3 cursor-pointer top-1/2 right-3`}
              >
                <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
              </svg>
            </div>
          </div>
          <div className="mr-6 basis-[20%] flex flex-col">
            <label className="text-gray-700 " htmlFor="name">
              To Date
            </label>
            <div className="relative block text-gray-400 cursor-pointer focus-within:text-gray-600">
              <DatePicker
                selected={to_date}
                // @ts-ignore
                onChange={(date) => setToDate(format(date, "yyyy-MM-dd"))}
                wrapperClassName="w-full"
                className="ring-primary px-3 mt-[0.5rem] border-primary-light border-[0.5px] h-[34px] w-full block rounded"
                dateFormat={"yyyy-MMM-dd"}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="currentColor"
                className={`absolute w-6 h-6 transform -translate-y-1/3 cursor-pointer top-1/2 right-3`}
              >
                <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
              </svg>
            </div>
          </div>

          <div className="basis-1/6">
            <CustomButton
              handleClick={applyFilter}
              className="w-24 h-[2.4rem]"
              title="Reset"
            />
          </div>
        </div>
        <DataTable
          keyField="sale_target_id"
          responsive
          striped
          pagination
          paginationServer
          //@ts-ignore
          paginationTotalRows={data?.paginator?.totalItems}
          progressPending={isFetching}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={
            <TableSkeleton
              columnNames={AllTargetColumnNames}
              actionRight
              row={10}
              col={1}
            />
          }
          progressComponent={
            <TableSkeleton
              columnNames={AllTargetColumnNames}
              actionRight
              row={10}
              col={1}
            />
          }
          //@ts-ignore
          columns={AllTargetColumns(editHandler, deleteHandler)}
          //@ts-ignore
          data={data?.data}
        />
      </PageWrapper>
    </AuthComponent>
  );
}
