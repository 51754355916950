import CustomForm from "../../../components/form/CustomForm";
import PageWrapper from "../../../components/layouts/PageWrapper";
import { useCallback, useMemo } from "react";
import { vehicleFields } from "../../../helpers/fields/trip/vehicle.fields";
import { vehicleSchema } from "../../../helpers/validation-schemas/trip/vehicle.schema";
import { useNavigate } from "react-router-dom";
import { useCreateUnitOfMeasurementMutation } from "../../../states/unit/unit-of-measurement.api";
import { useErrorHandling } from "../../../hooks/useErrorHandling";
import { castTypeArr } from "../../../utils/general/cast-type";
import { TFailResponse } from "../../../types/general/TFailResponse";
import { removeKeys } from "../../../utils/general/removeKeys";
import { TSuccessResponse } from "../../../types/general/TSuccessResponse";
import { Toast } from "../../../helpers/popups/Toast";
import { VEHICLE_MANAGEMENT } from "../../../utils/constants/vehicle-management.constants";
import { LoadingSpinner } from "../../../components/layouts/LoadingSpinner";
import { useCreateVehicleMutation } from "@states/trip/vehicle.api";
import AuthComponent from "@components/auth/component";

const VehicleAddPage = () => {
  const navigate = useNavigate();

  const [createVehicle, { isLoading, isSuccess, error: createError }] =
    useCreateVehicleMutation();

  useErrorHandling(...castTypeArr<TFailResponse>([createError]));

  const fields = useMemo(() => {
    return vehicleFields();
  }, []);

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const rest = removeKeys(reqBody, "remark");
        const res = await createVehicle({
          ...rest,
        });

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TSuccessResponse;

        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });

          navigate(VEHICLE_MANAGEMENT.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [createVehicle, isSuccess, Toast, navigate]
  );

  return (
    <AuthComponent
      action="create"
      module="Trip"
      subModule="Vehicle Management"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <CustomForm
          title={VEHICLE_MANAGEMENT.ADD_NEW_TITLE}
          schema={vehicleSchema}
          fields={fields}
          asyncDispatcher={asyncDispatcher}
        />
      </PageWrapper>
    </AuthComponent>
  );
};

export default VehicleAddPage;
