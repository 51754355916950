import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PRIMARY_INVOICE } from '@utils/constants/primary-sale-invoice.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const primaryInvoiceApi = createApi({
    reducerPath: 'primaryInvoiceApi',
    tagTypes: ['PrimaryInvoice'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllPrimaryInvoices: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                primary_sales_invoice_code?: string;
            }
        >({
            query: ({ page = 1, limit = 10, primary_sales_invoice_code }) => {
                const queryParams = {
                    url: PRIMARY_INVOICE.GET_ALL,
                    params: { page, limit },
                };

                if (primary_sales_invoice_code) {
                    //@ts-ignore
                    queryParams.params.primary_sales_invoice_code =
                        primary_sales_invoice_code;
                }

                return queryParams;
            },
        }),
        getPrimaryInvoice: builder.query<
            any,
            { primary_sales_invoice_id: string | number }
        >({
            query: ({ primary_sales_invoice_id }) => {
                console.log(
                    `${PRIMARY_INVOICE.GET_ONE}${primary_sales_invoice_id}`
                );
                return `${PRIMARY_INVOICE.GET_ONE}${primary_sales_invoice_id}`;
            },
        }),
        createPrimaryInvoice: builder.mutation({
            query: (body) => ({
                url: PRIMARY_INVOICE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updatePrimaryInvoice: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRIMARY_INVOICE.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deletePrimaryInvoice: builder.mutation({
            query: (id) => ({
                url: `${PRIMARY_INVOICE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
        getPrimarySaleOrderInvoicesPrint: builder.query<any, { id: string | number }>({
            query: ({ id }) => {
            return `${PRIMARY_INVOICE.GET_ONE_SALE_ORDER_FOR_PRINT}${id}`;
            },
        }),
    }),
    keepUnusedDataFor:0
});

export const {
    useGetAllPrimaryInvoicesQuery,
    useGetPrimaryInvoiceQuery,
    useLazyGetPrimaryInvoiceQuery,
    useCreatePrimaryInvoiceMutation,
    useUpdatePrimaryInvoiceMutation,
    useDeletePrimaryInvoiceMutation,
    useGetPrimarySaleOrderInvoicesPrintQuery,
} = primaryInvoiceApi;
