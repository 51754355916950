import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import CustomButton from "../../buttons/CustomButton";
import expandStyles from "../styles/expandStyles";
import ItemListTable from "@components/extra/sales-order/ItemListTable";
import TripProgressTable from "@components/extra/trip/TripProgress";
import { formatDBDate } from "@utils/general/format-db-date";
import { formatDate } from "@utils/general/format-date";
import PaymentReceiveItemListTable from "@components/extra/sales-order/PaymentReceiveItemListTable";

interface PaymentReceiveModalProps {
  data: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentReceiveModal: React.FC<PaymentReceiveModalProps> = ({
  data,
  isOpen,
  setIsOpen,
}) => {
  const [target, setTarget] = useState(1);

  const grandTotalSum = data?.product_detail?.reduce((acc: any, cur: any) => {
    return acc + cur.amount;
  }, 0);

  // const okayData = useMemo(
  //     () => [
  //         ...(data?.product_detail?.map((d: any, i: number) => ({
  //             ...d,
  //             no: i,
  //         })) || []),
  //         {
  //             product_code: 'Totals:',
  //             amount: grandTotalSum,
  //             noAction: true,
  //         },
  //     ],
  //     [data]
  // );

  console.log(data, "data");

  const changeTarget = () => {
    setTarget((prevTarget) => (prevTarget === 1 ? 2 : 1));
  };

  return (
    <Modal isOpen={isOpen} style={expandStyles} contentLabel="Example Modal">
      <div className="relative h-full">
        <div className="flex justify-end">
          <svg
            onClick={() => setIsOpen(false)}
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#3e3e3e"
            className="cursor-pointer"
          >
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
          </svg>
        </div>
        <div className="py-4 px-14">
          <div className="grid grid-cols-3 gap-x-8 gap-y-7">
            <div className="flex justify-between">
              <div className="font-[500] basis-5/12">Consignment Id</div>
              <div className="basis-1/12">:</div>
              <div className="basis-6/12 font-[300] text-[0.85rem]">
                {data.consignment_code}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-[500] basis-5/12">Started Date</div>
              <div className="basis-1/12">:</div>
              <div className="basis-6/12 font-[300] text-[0.85rem]">
                {formatDate(new Date(data.sales_date || data.invoice_date))}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-[500] basis-6/12">Payment Received Date</div>
              <div className="basis-1/12">:</div>
              <div className="basis-5/12 font-[300] text-[0.85rem]">
                {formatDate(new Date(data.payment_receive_date))}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="font-[500] basis-5/12">Status</div>
              <div className="basis-1/12">:</div>
              <div className="basis-6/12 font-[300] text-[0.85rem]">
                {data.status == 1
                  ? "Open"
                  : data.status == 2
                  ? "Invoice Created"
                  : data.status == 3
                  ? "Approved & Open"
                  : data.status == 4
                  ? "Rejected"
                  : "Closed"}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-[500] basis-5/12">Customer Name</div>
              <div className="basis-1/12">:</div>
              <div className="basis-6/12 font-[300] text-[0.85rem]">
                {data.customer_first_name +
                  " " +
                  (data.customer_last_name || "")}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-[500] basis-6/12">Business Unit Name</div>
              <div className="basis-1/12">:</div>
              <div className="basis-5/12 font-[300] text-[0.85rem]">
                {data.business_unit_name || "-"}
              </div>
            </div>
          </div>
          <div className="flex justify-between p-4 mt-6 mb-5 bg-gray-100">
            <div className="flex">
              <div className="font-semibold text-primary-dark">
                Payment Receive
              </div>
              <div className="mx-10 text-center"></div>
              <div className="font-semibold text-primary-dark">
                {data.payment_receive_code}
              </div>
            </div>
          </div>

          <PaymentReceiveItemListTable data={[data]} />
          <div className="flex flex-col p-2  my-10 ">
            <label
              className="mb-2 text-gray-700 capitalize"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              disabled
              value={data.description || "-"}
              rows={3}
              className="px-2 py-3 border-[0.7px] focus:ring-1 ring-primary focus:outline-none rounded border-primary-light"
              placeholder="Enter description"
            ></textarea>
          </div>

          <div className="flex w-full mt-5">
            <div className="w-1/2">
              <label className="mb-2 text-gray-700 capitalize" htmlFor="File">
                File
              </label>
              <img
                className="object-contain w-[150px]  h-72 border my-4"
                src={data.file}
                width={100}
                height={200}
                alt="file"
              />
            </div>
          </div>
          <div className="absolute bottom-4 right-16">
            <CustomButton
              handleClick={() => setIsOpen(false)}
              color="bg-primary-dark"
              className="px-8 py-3"
              title="Close"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentReceiveModal;
