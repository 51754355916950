export const GOODS_ISSUE_NOTE = {
    BASE_PATH: '/goods-request/goods-issue-note/',
    MAKE_ISSUE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-issue';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add Goods Issue Note',
    LIST_PAGE_TITLE: 'Goods Issue Note List',
    EDIT_PAGE_TITLE: 'Edit Goods issue Note',
    GET_ALL: 'goods-request/goods-issue-note',
    GET_ALL_MAKE_PAYMENTS: 'goods-issue-note/payment',
    GET_ONE: 'goods-request/goods-issue-note/edit/',
    CREATE: 'goods-request/goods-issue-note/create',
    UPDATE: 'goods-request/goods-issue-note/update/',
    DELETE: 'goods-request/goods-issue-note/delete/',
    DELETE_MAKE_PAYMENT: 'goods-issue/goods-issue-note/payment/delete/',
    MAKE_PAYMENT: 'goods-request/goods-issue-note/payment/update/',
    ADD_NEW_TITLE: 'Add New Goods issue Note',
    GET_ONE_FOR_PRINT: 'goods-request/goods-issue-note/print/',
};
