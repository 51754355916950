export const isQtyZeroInDetail = (productDetail: any) => {
  let isExit = productDetail.filter((product: any) => {
    return product.qty != 0;
  });

  if (isExit.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const isQtyZeroInDetailConsignment = (productDetail: any) => {
  let isExit = productDetail.filter((product: any) => {
    return product.consignment_contract_qty != 0;
  });

  if (isExit.length > 0) {
    return true;
  } else {
    return false;
  }
};
