import { z } from "zod";

export const consignmentSchema = z.object({
  sales_date: z.union(
    [
      z
        .string({
          message: "sales date is required",
        })
        .nonempty({
          message: "sales date is required",
        }),
      z.date(),
    ],
    {
      message: "sales date must be a valid date",
    }
  ),
  customer_id: z.union([
    z.string().nonempty({ message: "Customer Name is required" }),
    z.number(),
  ]),
  region_id: z.union([
    z
      .string({ message: "Distribution Region Name is required" })
      .nonempty({ message: "Distribution Region Name is required" }),
    z.number({ message: "Distribution Region Name is required" }),
  ]),

  business_unit_id: z.union([
    z
      .string({
        message: "Business Unit Name is required",
      })
      .nonempty({
        message: "Business Unit Name is required",
      }),
    z.number(),
  ]),
  consignment_contract_id: z.union([
    z
      .string({ message: "Customer Name is required" })
      .nonempty({ message: "Customer Name is required" }),
    z.number({ message: "Customer Name is required" }),
  ]),
  payment_type_id: z.union([
    z
      .string({
        message: "Payment Type is required",
      })
      .nonempty({
        message: "Payment Type is required",
      }),
    z.number(),
  ]),
  payment_term_id: z.union([
    z
      .string({
        message: "Payment Term is required",
      })
      .nonempty({
        message: "Payment Term is required",
      }),
    z.number(),
  ]),
  remark: z.string().optional().optional(),
  consignment_method: z.any().optional().optional(),
  description: z.string().optional().optional(),
  status: z.number().optional().optional(),
  reason: z.string().optional().optional(),
  product_detail: z.array(
    z.object({
      product_id: z.union([z.string(), z.number()]),
      unit_id: z.union([z.string(), z.number()]),
      sales_price: z.number(),
      contract_qty: z.number(),
      qty: z.number(),
      total_amount: z.number(),
      product_code: z.string().optional(),
      available_qty: z.any().optional(),
      old_contract_qty: z.any().optional(),
      product_name: z.string().optional(),
      unit_name: z.string().optional(),
      amount: z.number().optional().default(0),
      discount: z.number().optional().default(0),
      discount_amount: z.number().default(0),
      discount_type: z.string().optional().default("AMT"),
      tax: z.number().optional(),
      tax_amount: z.number().default(0),
      tax_type: z.string().optional().default("AMT"),
      warehouse_name: z.string().optional(),
      warehouse_id: z.union([z.string(), z.number()]).optional(),
    })
  ),
  tax: z.any().optional(),
  tax_type: z.string().optional(),
  tax_amount: z.number().default(0),
  discount: z.any().optional(),
  discount_type: z.string().optional(),
  discount_amount: z.number().default(0),
  other_charges: z
    .number({
      message: "Other Charges must be number",
    })
    .optional()
    .default(0),
  grand_total_amount: z.number().default(0),
  sub_total: z.number().default(0),
  current_amount: z.number().optional().nullable(),
  credit_balance: z.number().optional().nullable(),
});
export type ZConsignment = z.infer<typeof consignmentSchema>;

// export interface TConsignment extends ZConsignment {
//     consignment_id: number | string;
//     consignment_code: string;
//     customer_name: string;
//     payment_type_name: string;
//     status: string | number;
//     sales_date: string;
//     grand_total_amount: string;
// }
