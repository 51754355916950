export const CONSIGNMENT_RETURN = {
    BASE_PATH: '/consignment-return/',
    BASE_PATH_MAKE_PAYMENT: '/consignment-return/make-payment/',
    MAKE_PAYMENT_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Consignment Return',
    LIST_PAGE_TITLE: ' Consignment  Return List',
    EDIT_PAGE_TITLE: 'Edit Consignment Return',
    GET_ALL: 'consignment/return/',
    GET_ALL_MAKE_PAYMENTS: 'consignment-return/payment',
    GET_ONE: 'consignment/return/edit/',
    CREATE: 'consignment/return/create',
    UPDATE: 'consignment/return/update/',
    DELETE: 'consignment/return/delete/',
    DELETE_MAKE_PAYMENT: 'consignment/return/payment/delete/',
    MAKE_PAYMENT: 'consignment/return/payment/update/',
    ADD_NEW_TITLE: 'Add New Consignment Return',
};