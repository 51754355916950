import AuthComponent from "@components/auth/component";
import BackButton from "@components/buttons/BackButton";
import CustomButton from "@components/buttons/CustomButton";
import CustomInputField from "@components/form/CustomInputField";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import PageWrapper from "@components/layouts/PageWrapper";
import { Toast } from "@helpers/popups/Toast";
import {
  bankInformationSchema,
  ZBankInformation,
} from "@helpers/validation-schemas/bank-information/bank.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { useCreateBankInformationMutation } from "@states/bank-information/bank-information.api";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { BANK_INFORMATION } from "@utils/constants/bank-information.constants";
import { castTypeArr } from "@utils/general/cast-type";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function BankInformationAddPage() {
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState
  } = useForm<ZBankInformation>({
    resolver: zodResolver(bankInformationSchema),
  });

  const [createBankInformation, { isLoading, isSuccess, error: createError }] =
    useCreateBankInformationMutation();

  useErrorHandling(...castTypeArr<TFailResponse>([createError]));
  const onSubmit: SubmitHandler<ZBankInformation> = async (data: any) => {
    try {
      setSubmitLoading(true);
      const res = await createBankInformation(data);

      const successData = "data" in res ? res.data : null;
      const { success, message } = successData as TSuccessResponse;

      if (success || isSuccess) {
        await Toast.fire({
          title: message,
          icon: "success",
        }).then(() => setSubmitLoading(false));

        navigate("/bank-information");
      }
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  return (
    <AuthComponent
      action="create"
      module="Bank Information"
      subModule="Bank Information"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <BackButton />

        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
            {BANK_INFORMATION.ADD_NEW_TITLE}
          </h3>
          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <CustomInputField
              label="Payment Method Name"
              errors={formState.errors}
              name={"payment_method_name"}
              placeHolder="Enter Payment Method Name"
              register={register}
              required
            />
          </div>

          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            <CustomTextAreaField
              label="Description"
              key={"description"}
              errors={formState.errors}
              colSpan={3}
              name="description"
              register={register}
            />
          </div>

          <div className="ml-2 mt-14">
            <CustomButton
              isLarge
              title="Cancel"
              color="white"
              textColor="gray.800"
              className="mr-10"
              handleClick={() => navigate(-1)}
            />
            <CustomButton
              handleClick={handleSubmit(onSubmit)}
              isLarge
              title={isLoading ? "Loading..." : "Submit"}
              type="submit"
            />
          </div>
        </form>
      </PageWrapper>
    </AuthComponent>
  );
}
