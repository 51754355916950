import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

const PromotionItemListTable = (props: {
  data: any[];
  amount: number | string;
  qty: number | string;
  range_type: string; // Define range_type properly
}) => {
  console.log("Amount from promotion detail:", props.amount);
  console.log("Range Type from promotion detail:", props.range_type);
  console.log("Qty from promotion detail:", props.qty);

  const [highlightedRow, setHighlightedRow] = useState<any>(null);

  useEffect(() => {
    let targetValue =
      props.range_type === "MMK" ? Number(props.amount) : Number(props.qty);

    // Find row where targetValue falls within the range
    const rowInRange = props.data.find(
      (row) =>
        row.sale_range_from <= targetValue && row.sale_range_to >= targetValue
    );

    if (rowInRange) {
      setHighlightedRow(rowInRange);
    } else {
      // Select the last range if no range matches
      const lastRange = props.data.reduce((last, row) => {
        if (!last || row.sale_range_to > last.sale_range_to) {
          return row;
        }
        return last;
      }, null);

      setHighlightedRow(lastRange);
    }
  }, [props.amount, props.qty, props.range_type, props.data]);

  return (
    <DataTable
      columns={[
        {
          name: "Sale Range",
          selector: (row: any) =>
            `${row.sale_range_from} to ${row.sale_range_to}`,
        },
        {
          name: "Bouns",
          selector: (row: any) => `${row.bonus} ${row.bonus_type}`,
        },
        {
          name: "Cashback",
          selector: (row: any) => `${row.cashback} ${row.cashback_type}`,
        },

        {
          name: "Item Back",
          selector: (row: any) => row.item_name,
        },
        {
          name: "Number Of Item",
          selector: (row: any) => row.item_qty || 0,
        },
        {
          name: "Unit Name",
          selector: (row: any) => row.item_unit_name,
        },
      ]}
      data={props.data}
      striped
      responsive
      conditionalRowStyles={[
        {
          when: (row: any) => row === highlightedRow,
          style: {
            backgroundColor: "#ADD8E6", // Light blue highlight
          },
        },
      ]}
    />
  );
};

export default PromotionItemListTable;
