import CustomForm from "@components/form/CustomForm";
import CustomSearchModalSelectField from "@components/form/CustomSearchModalSelectField";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import PageWrapper from "@components/layouts/PageWrapper";
import useGetAllWarehouses from "@hooks/warehouse-and-inventory/useGetAllWarehouses";
import { TRIP_MANAGEMENT } from "@utils/constants/trip/trip.constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { removeKeys } from "@utils/general/removeKeys";
import { useCreateTripMutation } from "@states/trip/trip.api";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "@states/store";
import { LeavingSpinner } from "@components/layouts/LeavingSpinner";
import { tripFields } from "@helpers/fields/trip/trip.fields";
import { tripSchema } from "@helpers/validation-schemas/trip/trip.schema";
import {
  addRegions,
  addTripDetails,
  addWays,
  addAllocations,
  addVehicles,
  addUsers,
} from "@states/common/common.slice";
import {
  addCities,
  addStates,
  addTownships,
} from "@states/common/common.slice";
import { useGetAllStatesQuery } from "@states/common/common.api";
import useRegionEffect from "@hooks/distribution-region/useRegionEffect";
import TripDetailListTable from "@components/extra/trip/TripDetailListTable";
import RegionDetailTable from "@components/extra/distribution-region/RegionDetailTable";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import useGetAllRegions from "@hooks/distribution-region/useGetAllRegions";
import useGetAllVehicle from "@hooks/trip/useGetAllVehicle";
import useGetAllOfficeUsers from "@hooks/user-management/useGetAllOfficeUsers";
import TripProductDetailTable from "@components/extra/trip/TripProductDetailTable";
import { formatDBDate } from "@utils/general/format-db-date";
import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import AuthComponent from "@components/auth/component";

const TripManagementAddPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [productDetailLevel, setProductDetailLevel] = useState([]);
  const [leaving, setLeaving] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<any>();
  const { append, remove, update } = useFieldArray({
    control,
    name: "product_detail",
  });

  const {
    cities,
    townships,
    // regionDetail,
    isLoading: regionLoading,
  } = useRegionEffect();

  const { warehouseData } = useGetAllWarehouses({
    limit: 1000,
    warehouse_type: 2,
  });
  const { data: stateData } = useGetAllStatesQuery();
  const { officeUserData } = useGetAllOfficeUsers({ limit: 10000 });
  const [createTrip, { error: createError, isSuccess, isLoading }] =
    useCreateTripMutation();

  const { regionData } = useGetAllRegions({ limit: 100 });
  const { vehicleData } = useGetAllVehicle({ limit: 1000 });
  const {
    regions,
    ways,
    tripDetails,
    warehouses,
    states: storedStates,
    states,
    cities: storedCities,
    townships: storedTownships,
    regionDetails: regionDetail,
    vehicles,
    users,
    productDetails,
    formValues,
  } = useSelector((state: RootState) => state.common);
  console.log("warehouses selector", warehouses);
  console.log("formValues parent", formValues);
  const fields = useMemo(() => {
    return tripFields({
      data: {
        warehouses: warehouseData,
        states: stateData || [],
        cities: cities || [],
        townships: townships || [],
        regionData: regionData || [],
        vehicleData: vehicleData || [],
        officeUserData: officeUserData || [],
      },
      selectActions: {
        warehouseSelectionAction: addAllocations,
        stateSelectionAction: addStates,
        citySelectionAction: addCities,
        townshipSelectionAction: addTownships,
        vehicleSelectionAction: addVehicles,
        userSelectionAction: addUsers,
      },
      disabled: {
        cityDisabled: storedStates.length <= 0,
        townshipDisabled: storedCities.length <= 0,
      },
      selectedIds: {
        warehouseSelectedIds: warehouses.map((wh: any) => wh.warehouse_id),
        divisionSelectedIds: states?.map((s: any) => s.state_id),
        citySelectedIds: storedCities?.map((c: any) => c.city_id),
        townshipSelectedIds: storedTownships?.map((t: any) => t.township_id),
        vehicleSelectedIds: vehicles?.map((v: any) => v.vehicle_id),
        userSelectedIds: users?.map((v: any) => v.user_id),
      },
      values: {
        warehousesValue: "",
      },
    });
  }, [
    stateData,
    states,
    users,
    vehicles,
    cities,
    townships,
    warehouseData,
    warehouses,
    storedStates,
    storedCities,
    storedTownships,
    regionData,
    vehicleData,
    officeUserData,
  ]);
  // console.log("warehouseSelectedIds", warehouses);

  useErrorHandling(...castTypeArr<TFailResponse>([createError]));

  useEffect(() => {
    if (!createError) {
      dispatch(addAllocations([]));
      dispatch(addStates([]));
      dispatch(addCities([]));
      dispatch(addTownships([]));
    }
  }, [createError, dispatch]);

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        const rest = removeKeys(
          reqBody,
          "region_id",
          "way_id",
          "state_id",
          "township_id",
          "city_id",
          "warehouse_id"
        );
        const {
          attachment,
          proposal_date,
          way_id,
          state_id,
          township_id,
          city_id,
          warehouse_id,
          user_id,
          vehicle_id,
          ...data
        } = formValues;
        let productDetails = getValues("product_detail");
        const formattedProductDetails = productDetails?.map((pd: any) => ({
          product_id: pd.product_id,
          unit_id: pd.unit_id,
          qty: pd.qty,
        }));
        const formattedRegionDetails = regionDetail?.map((rd: any) => ({
          division_id: rd.state_id,
          city_id: rd.city_id,
          township_id: rd.township_id,
        }));
        const formData = new FormData();
        if (proposal_date) {
          formData.append("proposal_date", formatDBDate(proposal_date));
        }
        if (users) {
          formData.append(
            "users",
            JSON.stringify(users.map((pc: any) => pc.user_id))
          );
        }
        if (vehicles) {
          formData.append(
            "vehicles",
            JSON.stringify(vehicles.map((pc: any) => pc.vehicle_id))
          );
        }
        if (warehouses) {
          formData.append(
            "warehouses",
            JSON.stringify(warehouses.map((pc: any) => pc.warehouse_id))
          );
        }
        if (attachment[0]) {
          formData.append("attachment", attachment[0]);
        }
        if (formattedRegionDetails) {
          formData.append(
            "trip_details",
            JSON.stringify(formattedRegionDetails)
          );
        }
        if (formattedProductDetails) {
          formData.append(
            "product_detail",
            JSON.stringify(formattedProductDetails)
          );
        }
        for (const [key, value] of Object.entries(data)) {
          //@ts-ignore
          formData.append(key, value);
        }
        const response = await fetch(`${getBaseUrl()}trip/create`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY
            )}`, // Add token here
          },
        });

        const res = await response.json();

        const { success, message } = res as TSuccessResponse;

        // setCreateLoading(false);

        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });

          navigate(TRIP_MANAGEMENT.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }

        if (!success) {
          Toast.fire({
            title: message,
            icon: "error",
          });

          // setCreateLoading(false);

          return;
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [
      createTrip,
      isSuccess,
      Toast,
      navigate,
      regionDetail,
      warehouses,
      vehicles,
      users,
      getValues,
      formValues,
    ]
  );

  console.log("getValues product_Detail", getValues("product_detail"));
  return (
    <AuthComponent action="create" module="Trip" subModule="Trip Proposal" page>
      <PageWrapper>
        {leaving && <LeavingSpinner />}
        <CustomForm
          title={TRIP_MANAGEMENT.ADD_BUTTON_TITLE}
          schema={tripSchema}
          fields={fields}
          asyncDispatcher={asyncDispatcher}
          customComponent={<TripDetailListTable regionDetail={regionDetail} />}
          customComponent2={
            <TripProductDetailTable
              warehouses={warehouses}
              append={append}
              remove={remove}
              errors={errors}
              update={update}
              register={register}
              getValues={getValues}
              setError={setError}
              watch={watch}
              setValue={setValue}
              control={control}
            />
          }
        />
      </PageWrapper>
    </AuthComponent>
  );
};

export default TripManagementAddPage;
