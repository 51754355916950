import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CommonState {
  formValues: any;
  modules: any[];
  subModules: any[];
  regions: any[];
  ways: any[];
  warehouses: any[];
  customers: any[];
  states: any[];
  cities: any[];
  townships: any[];
  businessUnits: any[];
  regionDetails: any[];
  userRoleAssignDetails: any[];
  wayDetails: any[];
  tripDetails: any[];
  productDetails: any[];
  deletedRegionDetails: any[];
  deletedWayDetails: any[];
  deletedUserRoleAssignDetails: any[];
  deletedWays: any[];
  productCategories: any[];
  vehicles: any[];
  users: any[];
  assignModules: any[];
}

const initialState: CommonState = {
  formValues: {},
  modules: [],
  subModules: [],
  regions: [],
  ways: [],
  warehouses: [],
  states: [],
  cities: [],
  townships: [],
  businessUnits: [],
  regionDetails: [],
  wayDetails: [],
  tripDetails: [],
  userRoleAssignDetails: [],
  productDetails: [],
  deletedRegionDetails: [],
  deletedWayDetails: [],
  deletedUserRoleAssignDetails: [],
  deletedWays: [],
  productCategories: [],
  customers: [],
  vehicles: [],
  users: [],
  assignModules: [],
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    addFormValues(state, action: PayloadAction<any>) {
      state.formValues = action.payload;
    },
    addModules(state, action: PayloadAction<any[]>) {
      state.modules = action.payload;

      // filter sub modules and assign details
      if (state.subModules.length > 0) {
        const moduleIds = state.modules.map((m) => parseInt(m.module_id));

        const updatedSubModules = state.subModules.filter((sm) =>
          moduleIds.includes(parseInt(sm.module_id))
        );

        const updatedDetails = state.userRoleAssignDetails.filter((d) =>
          moduleIds.includes(parseInt(d.module_id))
        );

        state.subModules = updatedSubModules;
        state.userRoleAssignDetails = updatedDetails;
      }
    },
    addSubModules(state, action: PayloadAction<any[]>) {
      const subModules = action.payload;

      const withModuleName = subModules.map((subModule) => {
        return {
          ...subModule,
          module_name: state.modules.find(
            (module) => module.module_id == subModule.module_id
          ).module_name,
        };
      });

      state.subModules = withModuleName;

      state.userRoleAssignDetails = subModules.map((sm) => ({
        user_role_id: state.formValues.user_role_id,
        channel_id: state.formValues.channel_id,
        module_id: sm.module_id,
        sub_module_id: sm.sub_module_id,
        system_id: 1, // TODO: remove system but need to confirm fist
      }));
    },
    addProductDetail(state, action: PayloadAction<any>) {
      state.productDetails.push(action.payload);
    },

    addProductCategories(state, action: PayloadAction<any>) {
      state.productCategories = action.payload;
    },
    replaceProductDetail(state, action: PayloadAction<any>) {
      state.productDetails = action.payload;
    },
    // business unit
    addBusinessUnits(state, action: PayloadAction<any[]>) {
      state.businessUnits = action.payload;
    },
    removeBusinessUnit(state, action: PayloadAction<number | string>) {
      const newBusinessUnits = state.businessUnits.filter(
        (bu) => bu.business_unit_id != action.payload
      );

      state.businessUnits = newBusinessUnits;
    },
    addRegions(state, action: PayloadAction<any[]>) {
      state.regions = action.payload;
    },
    addAllocations(state, action: PayloadAction<any[]>) {
      state.warehouses = action.payload;
      // state.warehouses = action.payload.map((c) => ({
      //     warehouse_id: c.warehouse_id,
      //     warehouse_name: c.warehouse_name,
      // }));
    },
    addVehicles(state, action: PayloadAction<any[]>) {
      state.vehicles = action.payload;
      // state.warehouses = action.payload.map((c) => ({
      //     warehouse_id: c.warehouse_id,
      //     warehouse_name: c.warehouse_name,
      // }));
    },

    addUsers(state, action: PayloadAction<any[]>) {
      state.users = action.payload;
      // state.warehouses = action.payload.map((c) => ({
      //     warehouse_id: c.warehouse_id,
      //     warehouse_name: c.warehouse_name,
      // }));
    },

    // addAllocations(state, action: PayloadAction<any[]>) {
    //   const newWarehouses = action.payload;
    //   if (JSON.stringify(state.warehouses) !== JSON.stringify(newWarehouses)) {
    //     state.warehouses = newWarehouses;
    //   }
    // },
    addWays(state, action: PayloadAction<any[]>) {
      state.ways = action.payload;
    },

    addWayDetails(state, action: PayloadAction<any[]>) {
      state.wayDetails = action.payload.map((wd, i) => ({
        ...wd,
        way_details_id: wd.way_details_id || i,
      }));
    },
    addTripDetails(state, action: PayloadAction<any[]>) {
      state.tripDetails = action.payload.map((td, i) => ({
        ...td,
        trip_details_id: td?.trip_details_id || i,
      }));
    },
    removeRegion(state, action: PayloadAction<any>) {
      const newRegions = state.regions.filter(
        (r) => r.region_id != action.payload.region_id
      );
      state.regions = newRegions;

      state.deletedWayDetails.push(action.payload.way_details_id);
    },
    removeRegionDetail(state, action: PayloadAction<any>) {
      console.log(action.payload, state.regions);
      const newRegions = state.regions.filter(
        (r) => r.region_id != action.payload.region_id
      );
      state.regions = newRegions;

      state.deletedWayDetails.push(action.payload.way_details_id);
    },
    deleteRegionDetail(state, action: PayloadAction<any>) {
      const toDelete = action.payload;

      state.deletedRegionDetails.push(toDelete.region_detail_id);

      // region detail
      const newRegionDetails = state.regionDetails.filter(
        (rd) => rd.region_detail_id != toDelete.region_detail_id
      );
      state.regionDetails = newRegionDetails;

      // township
      const newTownships = state.townships.filter(
        (t) =>
          !(
            t.state_id == toDelete.state_id &&
            t.city_id == toDelete.city_id &&
            t.township_id == toDelete.township_id
          )
      );
      state.townships = newTownships;

      // city
      const doesCityExist = newRegionDetails.find(
        (nrd) => nrd.city_id == toDelete.city_id
      );

      if (!doesCityExist) {
        const newCities = state.cities.filter(
          (t) =>
            !(t.state_id == toDelete.state_id && t.city_id == toDelete.city_id)
        );
        state.cities = newCities;
      }

      // state
      const doesStateExist = newRegionDetails.find(
        (nrd) => nrd.state_id == toDelete.state_id
      );

      if (!doesStateExist) {
        const newStates = state.states.filter(
          (t) => !(t.state_id == toDelete.state_id)
        );
        state.states = newStates;
      }
    },
    emptyRegionDetail(state) {
      state.regionDetails = [];
    },
    emptyState(state) {
      state.cities = [];
      state.townships = [];
    },
    emptyCity(state) {
      state.cities = [];
    },
    emptyTownship(state) {
      state.townships = [];
    },
    emptyWayDetails(state) {
      state.wayDetails = [];
    },
    emptyTripDetails(state) {
      state.tripDetails = [];
    },
    emptyUserRoleAssignDetails(state) {
      state.userRoleAssignDetails = [];
    },
    resetFormValues(state) {
      state.formValues = {};
    },
    addStates(state, action: PayloadAction<any[]>) {
      console.log("action.payload from addStates", state?.states);

      // Filter out duplicates by `state_id`
      const uniqueStates = action.payload.filter(
        (item, index, self) =>
          self.findIndex((s) => s.state_id === item.state_id) === index
      );

      state.states = uniqueStates;
      // if(state.states){
      //     state.cities = []
      //     state.townships = []
      // }
      console.log("uniqueStates===>", uniqueStates);
      state.regionDetails.push(
        action.payload.map((s) => ({
          state_id: s.state_id,
          state_name: s.name,
        }))
      );
    },
    addCities(state, action: PayloadAction<any[]>) {
      const uniqueCities = action.payload.filter(
        (item, index, self) =>
          self.findIndex(
            (c) => c.state_id === item.state_id && c.city_id === item.city_id
          ) === index
      );
      state.cities = uniqueCities;
      state.regionDetails = action.payload.map((c) => ({
        state_id: c.state_id,
        state_name: c.state_name,
        city_id: c.city_id,
        city_name: c.cityname,
      }));
    },
    addTownships(state, action: PayloadAction<any[]>) {
      const uniqueTownships = action.payload.filter(
        (item, index, self) =>
          self.findIndex(
            (t) =>
              t.state_id === item.state_id &&
              t.city_id === item.city_id &&
              t.township_id === item.township_id
          ) === index
      );

      state.townships = uniqueTownships;
      state.regionDetails = action.payload.map((t, i) => ({
        region_detail_id: t.region_detail_id || i,
        state_id: t.state_id,
        state_name: t.state_name,
        city_id: t.city_id,
        city_name: t.city_name,
        township_id: t.township_id,
        township_name: t.townshipname,
      }));
    },
    addCustomers(state, action: PayloadAction<any[]>) {
      state.customers = action.payload;
    },
    removeSubModule(state, action: PayloadAction<any>) {
      const newSubModules = state.subModules.filter(
        (sm) => sm.sub_module_id != action.payload
      );

      const newUserRoleAssignDetails = state.userRoleAssignDetails.filter(
        (dtl) => dtl.sub_module_id != action.payload
      );

      const newModuleIds = newSubModules.map((sm) => sm.module_id);

      const newModules = state.modules.filter((m) =>
        newModuleIds.includes(m.module_id)
      );

      state.modules = newModules;
      state.subModules = newSubModules;
      state.userRoleAssignDetails = newUserRoleAssignDetails;
      state.deletedUserRoleAssignDetails.push(action.payload);
    },
    emptyDeletedUserRoleAssignDetails(state) {
      state.deletedUserRoleAssignDetails = [];
    },

    updateProductDetail(state, action: PayloadAction<any>) {
      console.log(action.payload, "updated Product Details");
      const { index, newProductDetail } = action.payload;

      // Ensure state.productDetails is initialized and check if the index exists
      if (state.productDetails[index]) {
        state.productDetails[index] = {
          ...state.productDetails[index],
          ...newProductDetail,
        };
      }
    },
    resetProductDetail: (state) => {
      state.productDetails = [];
    },
    addProductDetails: (state, action: PayloadAction<any>) => {
      state.productDetails = action.payload.product_detail || [];
    },

    removeProductDetail(state, action: PayloadAction<any>) {
      const updateProductDetails = state.formValues.product_detail.filter(
        (_: any, i: number) => i !== action.payload
      );

      state.formValues.product_detail = updateProductDetails;
    },
    removeAllProductDetail(state, action: PayloadAction<any>) {
      state.formValues.product_detail = action.payload;
    },
    // removeAllProductDetail(state, action: PayloadAction<any>) {
    //   //@ts-ignore
    //   if (state?.length == 0) return state; // Prevents unnecessary updates
    //   state.formValues.product_detail = [];
    //   return []; // Clear the state only when needed
    // },

    removeProductsDetail(state, action: PayloadAction<any>) {
      // const updateProductDetails = state.formValues.products.filter(
      //     (_: any, i: number) => i == action.payload
      // );
      const updateProductDetails = state.formValues.products.filter(
        (_: any, i: number) => i !== action.payload
      );

      state.formValues.products = updateProductDetails;
    },
    removeWay(state, action: PayloadAction<number | string>) {
      state.deletedWays.push(action.payload);
    },
  },
});

export const {
  addModules,
  addSubModules,
  resetProductDetail,
  addProductDetails,
  addUsers,
  addProductCategories,
  addRegions,
  addWays,
  addStates,
  addCustomers,
  addCities,
  addTownships,
  addBusinessUnits,
  removeBusinessUnit,
  removeRegion,
  removeRegionDetail,
  emptyCity,
  emptyState,
  emptyTownship,
  deleteRegionDetail,
  emptyRegionDetail,
  addWayDetails,
  addTripDetails,
  emptyWayDetails,
  emptyTripDetails,
  addFormValues,
  emptyUserRoleAssignDetails,
  resetFormValues,
  removeSubModule,
  emptyDeletedUserRoleAssignDetails,
  addProductDetail,
  updateProductDetail,
  removeProductDetail,
  removeProductsDetail,
  removeWay,
  replaceProductDetail,
  addAllocations,
  removeAllProductDetail,
  addVehicles,
} = commonSlice.actions;

export default commonSlice.reducer;
