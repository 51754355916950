import { z } from "zod";

export const goodsDeliverySchema = z.object({
  customer_id: z.union([z.string(), z.number()]).optional(),
  delivery_complete_date: z.union([z.string(), z.date()], {
    message: "Delivery date must be a valid date",
  }),
  status: z.number(),
  business_unit_name: z.union([z.string(), z.null()]).optional(),
  customer_name: z.union([z.string(), z.null()]).optional(),
  remark: z.string().optional(),
  delivery_date: z.any().optional(),
  description: z.string().optional(),
  driver_name: z.string().optional(),
  file: z.any(),
  product_detail: z.array(
    z.object({
      product_name: z.string().optional(),
      unit_name: z.string().optional(),
    })
  ),
});

export type ZGoodsDelivery = z.infer<typeof goodsDeliverySchema>;

export interface TGoodsDelivery extends ZGoodsDelivery {
  goods_delivery_note_id: number;
  reference_id: string;
  customer_name: string;
  customer_first_name: string;
  customer_last_name: string;
  invoice_date: string | number;
  request_date: string;
  warehouse_name: string;
  goods_delivery_note_code: string;
  goods_request_note_id: number;
  customer_phone: string | null;
  customer_address: string | null;
  business_unit_phone: string | null;
  business_unit_address: string | null;
  delivery_note_date: string;
}
