import PageWrapper from "@components/layouts/PageWrapper";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BackButton from "@components/buttons/BackButton";
import CustomTextAreaField from "@components/form/CustomTextAreaField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@states/store";
import {
  addFormValues,
  removeProductDetail,
  replaceProductDetail,
  resetFormValues,
  updateProductDetail,
} from "@states/common/common.slice";
import CustomButton from "@components/buttons/CustomButton";
import CustomDateField from "@components/form/CustomDateField";
import CustomSelectField from "@components/form/CustomSelectField";
import CustomInputField from "@components/form/CustomInputField";
import { thousandSeperator } from "@utils/general/digit-separators";
import {
  ZConsignmentReturn,
  consignmentReturnSchema,
} from "@helpers/validation-schemas/consignment/consignment-return.schema";
import { useCreatePrimarySaleReturnMutation } from "@states/primary-sales-return/sales-return.api";
import { useLazyGetPrimaryInvoiceQuery } from "@states/primary-sale-order/primary-invoice.api";
import useGetAllPrimaryInvoices from "@hooks/primary-sales-order/useGetAllPrimaryInvoices";
import { useDraggable } from "react-use-draggable-scroll";
import { PRIMARY_SALES_RETURN } from "@utils/constants/primary-sales-return/sales-return.constants";
import {
  useGetAllConsignmentInvoicesQuery,
  useLazyGetConsignmentInvoiceQuery,
} from "@states/consignment/consignment-invoice.api";
import {
  useCreateConsignmentReturnMutation,
  useUpdateConsignmentReturnMutation,
  useGetConsingmentReturnQuery,
} from "@states/consignment-sales-return/consignment-return.api";
import { CONSIGNMENT_RETURN } from "@utils/constants/consignment-sales-return/consignment-return.constants";
import { useGetAllConsignmentContractsQuery } from "@states/consignment/consignment-contract.api";
import useGetAllReturnReason from "@hooks/common/useGetAllReturnReason";
import { formatDBDate } from "@utils/general/format-db-date";
import AuthComponent from "@components/auth/component";
const ConsignmentReturnEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const tableContainerRef = useRef<any>();
  const { events } = useDraggable(tableContainerRef);

  const { formValues } = useSelector((state: RootState) => state.common);

  //@ts-ignore
  const { data: consignmentReturnData } = useGetConsingmentReturnQuery({
    consignment_return_id: id!,
  });

  const defaultValues = {
    return_date: consignmentReturnData?.data.return_date,
    consignment_contract_id:
      consignmentReturnData?.data.consignment_contract_id,
    return_reason_id: consignmentReturnData?.data.return_reason_id,
    description: consignmentReturnData?.data.description,
    product_detail: consignmentReturnData?.data.products,
    return_deduct_method: consignmentReturnData?.return_deduct_method || "AMT",
  };
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
    reset,
  } = useForm<ZConsignmentReturn>({
    defaultValues,
    resolver: zodResolver(consignmentReturnSchema),
  });
  const { returnReasonData } = useGetAllReturnReason();

  const { data: contractData } = useGetAllConsignmentContractsQuery({
    limit: 1000,
    status: 1,
  });
  const productBarcodeRef = useRef(null);

  const { append, remove, update, replace } = useFieldArray({
    control,
    name: "product_detail",
  });

  const {
    data: invoiceData,
    isFetching,
    error: getAllError,
    refetch,
  } = useGetAllConsignmentInvoicesQuery({});

  const [
    updateConsignmentReturn,
    { isLoading, isSuccess, error: createError },
  ] = useUpdateConsignmentReturnMutation();

  const [getConsignmentInvoice, { data }] = useLazyGetConsignmentInvoiceQuery();

  useErrorHandling(...castTypeArr<TFailResponse>([createError]));

  useEffect(() => {
    if (isSuccess && data) {
      Toast.fire({
        title: data.message,
        icon: "success",
      });

      navigate(CONSIGNMENT_RETURN.BASE_PATH, {
        state: {
          reload: true,
        },
      });
    }
  }, [isSuccess, data, navigate, Toast]);

  useEffect(() => {
    reset(defaultValues);
  }, [consignmentReturnData, reset]);

  useEffect(() => {
    if (formValues.consignment_invoice_id) {
      getConsignmentInvoice({
        consignment_invoice_id: formValues.consignment_invoice_id,
      });
    }
  }, [formValues.consignment_invoice_id]);

  // useEffect(() => {
  //     if (data?.data?.products) {
  //         const productDetails = data?.data?.products.map((product: any) => ({
  //             product_id: product.product_id,
  //             product_code: product.product_code,
  //             product_name: product.product_name,
  //             unit_id: product.unit_id,
  //             unit_name: product.unit_name,
  //             sales_price: product.sales_price,
  //             qty: product.qty,
  //             total_amount: product.total_amount,
  //             amount: product.qty * product.sales_price,
  //             discount_amount: product.discount_amount || 0,
  //             discount_type: product.discount_type || '-',
  //             tax_amount: product.tax_amount || 0,
  //             tax_type: product.tax_type || '-',
  //             return_qty: product.qty,
  //             return_amount: product.qty * product.sales_price,
  //         }));

  //         replace(productDetails);
  //         dispatch(replaceProductDetail(productDetails));
  //     }
  // }, [data]);

  useEffect(() => {
    if (consignmentReturnData?.data?.products) {
      const productDetails = consignmentReturnData?.data?.products.map(
        (product: any) => ({
          product_id: product.product_id,
          product_code: product.product_code,
          warehouse_id: product.warehouse_id,
          warehouse_name: product.warehouse_name,
          product_name: product.product_name,
          unit_id: product.unit_id,
          unit_name: product.unit_name,
          sales_price: product.sales_price,
          qty: product.qty,
          total_amount: product.total_amount,
          amount: product.qty * product.sales_price,
          normal_discount: product.normal_discount || 0,
          normal_discount_type: product.normal_discount_type,
          tax_amount: product.tax_amount || 0,
          tax_type: product.tax_type,
          tax: product.tax,
          discount: product.discount,
          return_qty: product.return_qty,
          return_amount: product.return_amount,
        })
      );

      replace(productDetails);
      dispatch(replaceProductDetail(productDetails));
    }
  }, [consignmentReturnData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const { product_detail, ...theRest } = value;

      const targetIndex = Number(name?.split(".")?.[1]);
      const targetProductDetail: any = product_detail![targetIndex!];

      if (targetProductDetail && product_detail) {
        if (targetProductDetail) {
          let rtnQty = targetProductDetail.return_qty;

          let returnAmount = 0;

          if (rtnQty > targetProductDetail.available_return_qty) {
            rtnQty = targetProductDetail.available_return_qty;
          }

          returnAmount =
            rtnQty === 0 ? 0 : rtnQty * targetProductDetail.sales_price;

          update(targetIndex, {
            ...targetProductDetail,
            return_amount: returnAmount,
            return_qty: rtnQty,
          });

          // TODO: check whether this is actaully necessary
          dispatch(
            updateProductDetail({
              index: targetIndex,
              product_detail: {
                ...targetProductDetail,
                return_amount: returnAmount,
                return_qty: rtnQty,
              },
            })
          );
        }
      }

      // Calculate sub_total
      const subTotal =
        //@ts-ignore
        product_detail?.reduce((acc, cur) => {
          if (cur) {
            return acc + (cur.return_amount || 0);
          }
          return acc;
        }, 0) || 0;

      const otherCharges = theRest.other_charges || 0;
      // const returnDeductAmount = theRest.return_deduct_amount || 0;

      const grandTotalAmount = subTotal + otherCharges;

      // Calculate discount_amount based on sub_total
      const returnDeductAmount =
        theRest.return_deduct_method == "%"
          ? grandTotalAmount * (theRest.return_deduct! / 100)
          : theRest.return_deduct || 0;

      //@ts-ignore
      dispatch(
        addFormValues({
          ...theRest,
          // @ts-ignore
          product_detail: product_detail?.map((pd) => ({ ...pd })),
          sub_total: subTotal,
          other_charges: otherCharges,
          grand_total_amount: grandTotalAmount,
          return_deduct_amount: returnDeductAmount,
          total_return_amount: grandTotalAmount - returnDeductAmount,
        })
      );
    });

    return () => {
      // @ts-ignore
      subscription.unsubscribe();
      dispatch(resetFormValues());
    };
  }, [watch, dispatch]);

  console.log(consignmentReturnData, "consignmentReturnData");

  const onSubmit: SubmitHandler<ZConsignmentReturn> = async (data) => {
    try {
      // async stuff here
      // console.log(data, 'data');
      await asyncDispatcher(data);
    } catch (error: any) {
      setError("root", {
        message: error.message,
      });
    }
  };

  const asyncDispatcher = useCallback(
    async (reqBody: any) => {
      try {
        console.log(formValues, "reqBody");
        const { product_detail, return_deduct, ...rest } = formValues;

        const formattedProductDetails = product_detail?.map((pd: any) => ({
          product_id: pd.product_id,
          warehouse_id: pd.warehouse_id,
          unit_id: pd.unit_id,
          sales_price: pd.sales_price,
          qty: pd.qty,
          amount: pd.amount,
          discount_type: pd.normal_discount_type,
          discount_amount: pd.normal_discount,
          tax_type: pd.tax_type,
          tax_amount: pd.tax_amount,
          tax: pd.tax,
          discount: pd.discount,
          total_amount: pd.total_amount,
          return_qty: pd.return_qty,
          return_amount: pd.return_amount,
        }));
        const res = await updateConsignmentReturn({
          id,
          body: {
            ...rest,
            description: rest.description,
            return_date: formatDBDate(rest.return_date),
            // balance: rest.grand_total_amount,
            products: formattedProductDetails,
          },
        });
        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TSuccessResponse;
        if (success || isSuccess) {
          await Toast.fire({
            title: message,
            icon: "success",
          });
          navigate(CONSIGNMENT_RETURN.BASE_PATH, {
            state: {
              reload: true,
            },
          });
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [updateConsignmentReturn, isSuccess, Toast, navigate, formValues, data]
  );

  console.log("ERRORS", errors);
  return (
    <AuthComponent
      action="edit"
      module="Consignment Return"
      subModule="Consignment Return"
      page
    >
      <PageWrapper>
        {isLoading && <LoadingSpinner />}
        <BackButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="mb-4 ml-2 text-2xl font-[600] text-primary-dark uppercase">
            Edit Consignment Return
          </h3>
          <div className="grid grid-cols-6 gap-x-4 gap-y-8">
            {/* <CustomSelectField
            errors={errors}
            name="consignment_invoice_id"
            label="Consignment Invoice ID"
            placeHolder="Select Consignment invoice ID"
            //@ts-ignore
            control={control}
            //@ts-ignore

            options={invoiceData?.data?.map((invoice) => ({
              value: invoice.consignment_invoice_id,
              label: invoice.consignment_invoice_code,
            }))}
            required
          /> */}
            <CustomSelectField
              errors={errors}
              name="consignment_contract_id"
              label="Consignment Contract"
              placeHolder="Select Consignment Contract"
              //@ts-ignore
              control={control}
              //@ts-ignore
              options={contractData?.data?.map((item: any) => ({
                label: item.consignment_contract_code,
                value: item.consignment_contract_id,
              }))}
              required
              disabled
            />

            <CustomDateField
              errors={errors}
              name="return_date"
              label="Return Date"
              register={register}
              key="return_date"
              //@ts-ignore
              control={control}
              required
              disabled
              // minDate={new Date(contractProductDetailData?.invoice_date)}
            />

            <CustomSelectField
              errors={errors}
              name="return_reason_id"
              label="Return Reason"
              placeHolder="Select Return Reason"
              //@ts-ignore
              control={control}
              disabled
              //@ts-ignore
              options={returnReasonData?.map((item: any) => ({
                label: item.reason_name,
                value: item.return_reason_id,
              }))}
              required
            />
            <div></div>
            {/* <CustomTextAreaField
                        key={'remark'}
                        colSpan={3}
                        errors={errors}
                        name="remark"
                        register={register}
                    /> */}

            <CustomTextAreaField
              key={"description"}
              errors={errors}
              colSpan={6}
              name="description"
              register={register}
            />
            <div className="flex flex-col justify-between col-span-6">
              <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                <span className=" text-[1.3rem]">Product Detail</span>
              </h3>
            </div>
            <div className="container w-full -mt-4 col-span-full">
              <div
                ref={tableContainerRef}
                {...events}
                className="w-[100%] mx-auto cursor-grab active:cursor-grabbing overflow-x-auto hide-scrollbar"
              >
                <table className="min-w-full overflow-x-scroll custom-table">
                  <thead className="h-12 py-3 text-xs font-light text-netural-600 bg-neutral-100">
                    <th className="text-xs font-light bg-neutral-100 2xl:text-sm">
                      Product Code
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Product Name
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Allocation Account Name
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">Unit</th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Sales Price
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Contract Qty
                    </th>
                    <th className="text-xs font-light 2xl:text-sm">
                      Available Return Qty
                    </th>
                    {/* <th className="text-xs font-light 2xl:text-sm">Amount</th>   */}
                    <th className="text-xs font-light 2xl:text-sm">
                      Normal Discount
                    </th>
                    {/* <th className="text-xs font-light 2xl:text-sm">Tax</th> */}
                    <th className="text-xs font-light 2xl:text-sm">
                      Total Amount
                    </th>
                    <th className="text-xs font-light 2xl:text-sm sticky right-[163px] 2xl:right-[171px] bg-inherit">
                      Return Quantity
                    </th>
                    <th className="sticky right-0 text-xs font-light 2xl:text-sm bg-inherit">
                      Return Amount
                    </th>
                  </thead>
                  <tbody className="text-center">
                    {formValues?.product_detail?.map(
                      (product_detail: any, i: number) => {
                        // console.log(product_detail);
                        return (
                          <tr key={i}>
                            <td>
                              <CustomInputField
                                readOnly
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                disabled
                                name={`product_detail.${i}.product_code`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.product_code}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                disabled
                                name={`product_detail.${i}.product_name`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.product_name}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                disabled
                                name={`product_detail.${i}.warehouse_name`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.warehouse_name}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.unit_name`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.unit_name}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.sales_price`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.sales_price}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.qty`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.qty}
                              />
                            </td>
                            <td>
                              <CustomInputField
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.available_return_qty`}
                                register={register}
                                setValue={setValue}
                                value={product_detail.available_return_qty}
                              />
                            </td>
                            {/* <td>
                            <CustomInputField
                              readOnly
                              disabled
                              noLabel
                              classNames="w-[100%]"
                              errors={errors}
                              name={`product_detail.${i}.amount`}
                              register={register}
                              setValue={setValue}
                              value={product_detail.amount}
                            />
                          </td> */}
                            <td>
                              {/* <CustomInputField
                              readOnly
                              disabled
                              noLabel
                              classNames="w-[100%]"
                              errors={errors}
                              name={`product_detail.${i}.normal_discount`}
                              register={register}
                              setValue={setValue}
                              value={product_detail.normal_discount}
                            /> */}
                              <div className="flex items-center">
                                <CustomInputField
                                  inputType="number"
                                  classNames="-mr-3 w-[130px]"
                                  noLabel
                                  errors={errors}
                                  name={`product_detail.${i}.normal_discount`}
                                  register={register}
                                  disabled={true}
                                />
                                <select
                                  value={
                                    product_detail?.normal_discount_type ||
                                    "AMT"
                                  }
                                  disabled={true}
                                  className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                >
                                  <option value="AMT">AMT</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </td>
                            {/* <td>
                            <CustomInputField
                              readOnly
                              disabled
                              noLabel
                              classNames="w-[100%]"
                              errors={errors}
                              name={`product_detail.${i}.tax_amount`}
                              register={register}
                              setValue={setValue}
                              value={product_detail.tax_amount}
                            />
                          </td> */}
                            <td>
                              <CustomInputField
                                setValue={setValue}
                                readOnly
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.total_amount`}
                                register={register}
                                value={product_detail.total_amount}
                              />
                            </td>
                            <td className="sticky right-[163px] 2xl:right-[171px] bg-inherit">
                              <CustomInputField
                                min={
                                  product_detail.available_return_qty == 0
                                    ? 0
                                    : 1
                                }
                                noLabel
                                classNames="w-[100%] min-w-[170px]"
                                errors={errors}
                                name={`product_detail.${i}.return_qty`}
                                register={register}
                                inputType="number"
                                max={product_detail.available_return_qty}
                              />
                            </td>
                            <td className="sticky right-0 bg-inherit">
                              <CustomInputField
                                readOnly
                                setValue={setValue}
                                disabled
                                noLabel
                                classNames="w-[100%]"
                                errors={errors}
                                name={`product_detail.${i}.return_amount`}
                                register={register}
                                value={product_detail.return_amount}
                              />
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              {formValues?.product_detail?.length > 0 && (
                <div className="bg-zinc-100 rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                  <div className="flex items-center mb-4">
                    <h5 className="text-[1.05rem] basis-3/12">Sub Total</h5>
                    <span className="s basis-1/12">:</span>
                    <div className="flex items-center basis-6/12"></div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.sub_total || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-4">
                    <h5 className=" basis-3/12 text-[1.05rem]">
                      Other Charges
                    </h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        defaultValue={0}
                        classNames="-mr-3 w-[200px]"
                        noLabel
                        inputType="number"
                        errors={errors}
                        name={"other_charges"}
                        placeHolder=" "
                        register={register}
                        disabled
                      />
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.other_charges || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-4">
                    <h5 className="text-[1.05rem] basis-3/12">Grand Total</h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12"></div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      {thousandSeperator(formValues.grand_total_amount || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className=" basis-3/12 text-[1.05rem]">
                      Return Deduct Amount
                    </h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12">
                      <CustomInputField
                        defaultValue={0}
                        classNames="-mr-3 w-[130px]"
                        noLabel
                        disabled
                        inputType="number"
                        errors={errors}
                        name={"return_deduct"}
                        placeHolder=" "
                        register={register}
                      />
                      <select
                        {...register("return_deduct_method")}
                        className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                        disabled
                      >
                        <option selected value="AMT">
                          AMT
                        </option>
                        <option value="%">%</option>
                      </select>
                    </div>
                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                      -{thousandSeperator(formValues.return_deduct_amount || 0)}
                    </h5>
                  </div>
                  <div className="flex items-center mb-2">
                    <h5 className="text-xl font-semibold basis-3/12">
                      Total Return Amount
                    </h5>
                    <span className=" basis-1/12">:</span>
                    <div className="flex items-center basis-6/12"></div>
                    <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                      {thousandSeperator(formValues.total_return_amount || 0)}
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ml-2 mt-14">
            <CustomButton
              isLarge
              title="Cancel"
              color="white"
              textColor="gray.800"
              className="mr-10"
              handleClick={() => navigate(-1)}
            />
            <CustomButton
              handleClick={handleSubmit(onSubmit)}
              isLarge
              title={isSubmitting ? "Loading..." : "Submit"}
              disabled={isSubmitting}
              type="submit"
            />
          </div>
          {errors.root && (
            <div className="mt-6 text-red-500">{errors.root.message}</div>
          )}
        </form>
      </PageWrapper>
    </AuthComponent>
  );
};

export default ConsignmentReturnEditPage;
