import { formatDBDate } from "@utils/general/format-db-date";
import React from "react";

export default function PromotionCard({
  promotionProductData,
  bonus,
  cash_back,
}: any) {
  return (
    <div>
      <div className="bg-[#D3E4F8] shadow-md rounded-lg w-[360px] mt-8 p-6 border border-gray-200">
        {/* Start Date */}
        <div className="flex justify-between items-center border-b pb-2 mb-2">
          <h5 className="text-black">Start Date</h5>
          {/* <span className="text-gray-500">:</span> */}
          <h5 className="text-[#002C76]">
            {formatDBDate(promotionProductData?.start_date)}
          </h5>
        </div>

        {/* End Date */}
        <div className="flex justify-between items-center border-b pb-2 mb-2">
          <h5 className="text-black">End Date</h5>
          {/* <span className="text-gray-500">:</span> */}
          <h5 className="text-[#002C76]">
            {formatDBDate(promotionProductData?.end_date)}
          </h5>
        </div>

        {/* Customer Channel */}
        <div className="flex justify-between items-center mb-2">
          <h5 className="text-black">Customer Channel</h5>
          {/* <span className="text-gray-500">:</span> */}
          <h5 className="text-[#002C76] text-right">
            {promotionProductData?.customer_channel
              ?.map((channel: any) => channel.customer_channel_name)
              .join(", ")}
          </h5>
        </div>

        <div className="flex justify-between items-center mb-2">
          <h5 className="text-black">Bonus</h5>
          {/* <span className="text-gray-500">:</span> */}
          <h5 className="text-[#002C76] text-right">{bonus || 0}</h5>
        </div>
        <div className="flex justify-between items-center mb-2">
          <h5 className="text-black">CashBack</h5>
          {/* <span className="text-gray-500">:</span> */}
          <h5 className="text-[#002C76] text-right">{cash_back || 0}</h5>
        </div>
      </div>
    </div>
  );
}
