const useGetPermission = () => {
  const permissionFromLocalStorage = JSON.parse(
    localStorage.getItem("dms-user-permission") as string
  );

  function hasAccess(action: string, module: string, submodule: string) {
    const feature = permissionFromLocalStorage.find(
      (p: any) =>
        p.module_name.toLowerCase() == module.toLowerCase() &&
        p.sub_module_name.toLowerCase() == submodule.toLowerCase()
    );

    if (!feature) return false;
    return feature.actions
      .map((ac: string) => ac.toLowerCase())
      .includes(action.toLowerCase());
  }

  function hasModuleAccess(module: string) {
    const feature = permissionFromLocalStorage.find(
      (p: any) => p.module_name.toLowerCase() == module.toLowerCase()
    );

    return feature ? true : false;
  }

  function hasSubModuleAccess(module: string, submodule: string) {
    const feature = permissionFromLocalStorage.find(
      (p: any) =>
        p.module_name.toLowerCase() == module.toLowerCase() &&
        p.sub_module_name.toLowerCase() === submodule.toLowerCase()
    );

    return feature ? true : false;
  }

  return {
    permissions: permissionFromLocalStorage,
    hasAccess,
    hasModuleAccess,
    hasSubModuleAccess,
  };
};

export default useGetPermission;
