import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import PageWrapper from "@components/layouts/PageWrapper";
import Search from "@components/layouts/Search";
import { useLocation, useNavigate } from "react-router-dom";
import usePagination from "@hooks/usePagination";
import useSearch from "@hooks/useSearch";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { castTypeArr } from "@utils/general/cast-type";
import { TFailResponse } from "@type-defs/general/TFailResponse";
import { useDidMountEffect } from "@hooks/useDidMountEffect";
import { DeletePopup } from "@helpers/popups/DeletePopup";
import TableSkeleton from "@components/layouts/TableSkeleton";
import { TDeleteSuccesResponse } from "@type-defs/general/TDeleteSuccessResponse";
import { Toast } from "@helpers/popups/Toast";
import {
  useDeletePrimaryInvoiceMutation,
  useGetAllPrimaryInvoicesQuery,
} from "@states/primary-sale-order/primary-invoice.api";
import { useLazyGetOneConsignmentPaymentReceiveQuery } from "@states/consignment/consignment-payment-receive.api";

import {
  useGetAllConsignmentInvoicesQuery,
  useDeleteConsignmentInvoiceMutation,
} from "@states/consignment/consignment-invoice.api";
import { invoiceColumns } from "@helpers/columns/consignment/invoice-columns";
import { PRIMARY_INVOICE } from "@utils/constants/primary-sale-invoice.constants";
import { TPrimaryInvoice } from "@helpers/validation-schemas/primary-sale-order/invoice.schema";
import { CONSIGNMENT_INVOICE } from "@utils/constants/consignment/consignment-invoice.constants";
import CollaspseIcon from "@components/icons/CollaspseIcon";
import ExpandIcon from "@components/icons/ExpandIcon";
import PaymentReceiveModal from "@components/modals/consignment/PaymentReceiveModal";

import { formatDate } from "../../../utils/general/format-date";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { faker } from "@faker-js/faker";
import AuthComponent from "@components/auth/component";
const ConsignmentInvoicePage = () => {
  const { page, limit, handlePageChange, handlePerRowsChange } =
    usePagination();
  const { search, setSearch } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedRowIds, setExpandedRowIds] = React.useState<
    number[] | string[] | any[]
  >([]);
  const [isPaymentReceiveOpen, setIsPaymentReceiveOpen] = React.useState(false);

  const {
    data,
    isFetching,
    error: getAllError,
    refetch,
  } = useGetAllConsignmentInvoicesQuery({
    page,
    limit,
    search: search,
  });
  const [
    getOneConsignmentPaymentReceive,
    {
      data: getOneConsignmentPaymentReceiveData,
      isLoading: getOneConsignmentPaymentReceiveLoading,
    },
  ] = useLazyGetOneConsignmentPaymentReceiveQuery();

  const [deleteConsignmentInvoice, { error: deleteError, isLoading }] =
    useDeleteConsignmentInvoiceMutation();

  // a custom hook that will handle fetch/mutation errors
  useErrorHandling(...castTypeArr<TFailResponse>([getAllError, deleteError]));

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (location.state?.reload) {
      refetch();
    }
  }, [location.state?.reload]);

  const editHandler = (invoice: any) => {
    //TODO: backend needs to provide sales_date in GET ONE response
    navigate(
      `${CONSIGNMENT_INVOICE.BASE_PATH}${invoice.consignment_invoice_id}/edit`,
      {
        state: {
          sales_date: invoice.sales_date,
        },
      }
    );
  };
  const handlePaymentReceiveModalOpen = (id: string | number) => {
    getOneConsignmentPaymentReceive({
      consignment_payment_receive_id: id,
    }).then((res) => {
      if (res.data) {
        setIsPaymentReceiveOpen(true);
      }
    });
  };
  const deleteHandler = async (id: number | string) => {
    try {
      const { isConfirmed } = await DeletePopup.fire();

      if (isConfirmed) {
        const res = await deleteConsignmentInvoice(id);

        const successData = "data" in res ? res.data : null;
        const { success, message } = successData as TDeleteSuccesResponse;

        if (success) {
          Toast.fire({
            icon: "success",
            title: message,
          });

          refetch();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthComponent action="view" module="Consignment" subModule="Invoice" page>
      <React.Fragment>
        {getOneConsignmentPaymentReceiveLoading && <LoadingSpinner />}
        <Search setSearch={setSearch} />
        <PageWrapper title="Invoice List">
          <PaymentReceiveModal
            //@ts-ignore
            data={getOneConsignmentPaymentReceiveData?.data || {}}
            isOpen={isPaymentReceiveOpen}
            setIsOpen={setIsPaymentReceiveOpen}
          />
          <DataTable
            keyField="primary_sales_invoice_id"
            responsive
            striped
            pagination
            paginationServer
            //@ts-ignore
            paginationTotalRows={data?.paginator?.totalItems}
            progressPending={isFetching}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            noDataComponent={<TableSkeleton row={10} col={3} />}
            progressComponent={<TableSkeleton row={10} col={3} />}
            conditionalRowStyles={[
              {
                when: (row) => expandedRowIds.includes(row.consignment_id),
                style: {
                  backgroundColor: "#D3E4F8",
                  // color: 'white',
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              },
              {
                when: (row) => row.no === "Totals:",
                style: {
                  backgroundColor: "#F1F2F5",
                  fontWeight: 500,
                },
              },
            ]}
            onRowExpandToggled={(expanded, row) => {
              setExpandedRowIds((prev) => {
                if (expanded && !prev.includes(row.consignment_invoice_id)) {
                  return [...prev, row.consignment_invoice_id];
                } else {
                  return prev.filter((id) => id !== row.consignment_invoice_id);
                }
              });
              return <div>A</div>;
            }}
            expandableRows
            expandableRowDisabled={(row) =>
              row.payment_receive_data == null || row.no == "Totals:"
            }
            expandableInheritConditionalStyles
            expandableIcon={{
              collapsed: <CollaspseIcon />,
              expanded: <ExpandIcon />,
            }}
            expandableRowsComponent={(row) => {
              console.log(row);
              return (
                <>
                  {/* Payment Receive */}
                  {/* @ts-ignore */}
                  {row.data?.payment_receive_data?.map(
                    //   @ts-ignore
                    (paymentReceive, index) => (
                      <div className="grid w-full h-full pl-[8%] min-w-full grid-cols-6 p-[0.89rem] text-primary-dark bg-gray-100">
                        <div className="flex items-center justify-center">
                          {index + 1}
                        </div>
                        <div className="flex items-center font-semibold">
                          Payment Receive
                        </div>
                        <div
                          onClick={() =>
                            handlePaymentReceiveModalOpen(
                              paymentReceive.consignment_payment_receive_id
                            )
                          }
                          className="flex items-center italic font-semibold underline cursor-pointer "
                        >
                          {paymentReceive.payment_receive_code}
                        </div>
                        <div className="flex items-center font-semibold">
                          {paymentReceive.payment_receive_amount}
                        </div>
                        <div className="flex items-center">
                          {formatDate(
                            new Date(paymentReceive.payment_receive_date)
                          )}
                        </div>
                        <div className="flex items-center">-</div>
                      </div>
                    )
                  )}
                </>
              );
            }}
            //@ts-ignore
            columns={invoiceColumns(editHandler, deleteHandler)}
            //@ts-ignore
            data={data?.data}
          />
        </PageWrapper>
      </React.Fragment>
    </AuthComponent>
  );
};

export default ConsignmentInvoicePage;
